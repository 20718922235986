import { CommonModule } from '@angular/common';
import { Component, computed, Input, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '../button/button.component';
import { OutsideClickDirective } from '../../directives/outside-click.directive';
import { LabelService } from '../../services/label.service';
import * as Types from '../../shared/types';
import { Dropdown, LabelTypes } from '../../shared/label.types';
import { FilterService } from '../../services/filter.service';
import { FilterItemComponent } from './filter-item/filter-item.component';
import { FilterClusterComponent } from './filter-cluster/filter-cluster.component';
import { DragDropModule, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SharedDataService } from '../../services/shared-data.service';
import { CustomSelectComponent } from '../custom-select/custom-select.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { FilterSaveDropdownComponent } from './filter-save-dropdown/filter-save-dropdown.component';
import { CommonService } from '../../services/common.service';
import { OutsideClickDirective_v2 } from '../../directives/app-outside-click.directive';

@Component({
  selector: 'filter-section',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonComponent,
    OutsideClickDirective,
    DragDropModule,
    FilterItemComponent,
    FilterClusterComponent,
    CustomSelectComponent,
    TableFilterComponent,
    FilterSaveDropdownComponent,
    OutsideClickDirective_v2
  ],
  templateUrl: './filter-section.component.html',
  styleUrl: './filter-section.component.scss',
})
export class FilterSectionComponent {
  constructor(
    public labelService: LabelService,
    private filterService: FilterService,
    private sharedDataService: SharedDataService,
    private commonService: CommonService
  ) { }

  @Input() buttonTitle: string = '';
  protected _filterDataDisplayed = computed<boolean>(() => this.filterService.filterDataDisplayedStatus());
  protected _dropdownOpened = signal<boolean>(false);
  dropdown: Dropdown = {} as Dropdown;
  filterData: Array<Types.FilterCondition> = [];
  applyButtonActive: boolean = false;

  ngOnInit() {
    this.labelService.getLabelData().subscribe((data: LabelTypes) => {
      this.dropdown = data?.dropdown ?? {};
    });
    this.filterService.getFilterCondition().subscribe((data) => {
      this.filterData = [...data];
    });

    this.filterService.getApplyButtonStatus().subscribe((status) => {
      this.applyButtonActive = status;
    });

    this.sharedDataService.getToggleValues().subscribe((values) => {
      this._dropdownOpened.set(values.filter);
    });
  }

  getFilterList() {
    this.commonService.getFiltersList({}).subscribe((data) => {
      if (data && data.length) {
        this.filterService.setSavedFilterList(data);
      }
    });
  }

  toggleDropdown = () => {
    this.getFilterConditionDataValue();
    if (!this._dropdownOpened()) {
      this.getFilterList();
    }
    this.sharedDataService.toggleFilter(!this._dropdownOpened());
  };

  getFilterConditionDataValue = () => {
    const tempData = this.filterService.getFilterConditionDataValue();
    this.filterData = [...tempData];
  };

  handleAddCondition = () => {
    this.filterService.addNewCondition();
  };

  onOutsideClick = () => {
    this.sharedDataService.toggleFilter(false);
  };

  handleClearFilters = () => {
    this.filterService.clearFilters();
    this.getFilterConditionDataValue();
    if (this._filterDataDisplayed()) {
      this.filterService.setRefreshDataStatus(true);
      this.filterService.setFilterDataDisplayedStatus(false);
    }
  };

  handleApplyFilters = () => {
    if (this.applyButtonActive) {
      this.getFilterConditionDataValue();
      this.filterService.setFilterDataDisplayedStatus(true);
      this.filterService.setRefreshDataStatus(true);
      this.sharedDataService.toggleFilter(false);
    }
  };

  drop(event: CdkDragDrop<Types.FilterCondition[]>) {
    moveItemInArray(this.filterData, event.previousIndex, event.currentIndex);
    if (event.previousIndex !== event.currentIndex) {
      this.filterService.reIndexArrayAfterDragDrop(this.filterData);
    }
  }
  toggleMoreDropdown() { }

  get isScrollable(): boolean {
    return this.filterData.length > 5;
  }
}
