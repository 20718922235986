import { CommonModule } from '@angular/common';
import { Component, ViewChild, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import * as Papa from 'papaparse';

import { OutsideClickDirective } from '../../directives/outside-click.directive';
import { DropdownObjectType } from '../../shared/types';
// import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NotificationService } from '../../services/notification.service';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { LabelService } from '../../services/label.service';
import { Filters, LabelTypes } from '../../shared/label.types';
import { MatTooltip } from '@angular/material/tooltip';
import { TooltipPipe } from './tooltip.pipe';

@Component({
  selector: 'custom-select',
  standalone: true,
  imports: [CommonModule, FormsModule, OutsideClickDirective, TruncatePipe, MatTooltip, TooltipPipe],
  templateUrl: './custom-select.component.html',
  styleUrl: './custom-select.component.scss',
})
export class CustomSelectComponent {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement> | undefined;

  @Input() selectedValue: DropdownObjectType | undefined | null = { label: '', value: '' };
  @Input() listHeader: string = 'Select Field';
  @Input() contentList: Array<DropdownObjectType> = [];
  @Input() showSearch: boolean = true;
  @Input() showHiButton: boolean = false;
  @Output() onItemSelect = new EventEmitter<DropdownObjectType>();
  @Output() onFileUploadSuccess = new EventEmitter<string>();
  @Output() hiButtonClicked = new EventEmitter<void>();
  @Output() onFileDataParsed = new EventEmitter<{ fileName: string; parsedData: Array<{ [key: string]: string }> }>();
  dropdownOpened: boolean = false;
  searchQuery: string = '';
  fileName: string | null = null;
  filters: Filters = {} as Filters;

  csvData: Array<{ [key: string]: string }> = []; // Property to store CSV data

  constructor(
    public notificationService: NotificationService,
    private labelService: LabelService
  ) { }
  ngOnInit() {
    this.labelService.getLabelData().subscribe((data: LabelTypes) => {
      this.filters = data?.filter ?? {};
    });
  }

  triggerFileInput() {
    if (this.fileInput) {
      this.fileInput.nativeElement.click(); // Trigger file input click
    }
    this.hiButtonClicked.emit();
  }

  handleFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      if (file.type === 'text/csv') {
        this.fileName = file.name;
        //console.log('CSV file selected:', file.name);
        this.dropdownOpened = false;

        // Read and parse the CSV file
        const reader = new FileReader();
        reader.onload = (e) => {
          const csvData = e.target?.result as string;
          //console.log('CSV Data:', csvData);
          this.parseCSV(csvData);
        };
        reader.readAsText(file);
      } else {
        this.notificationService.error('Invalid file type', 'Please upload a .csv file.');
        this.fileName = null;
      }
    } else {
      this.fileName = null;
    }
  }

  parseCSV(csvData: string) {
    Papa.parse(csvData, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const headers = result.meta.fields as string[];

        if (!headers.includes('Contact Id')) {
          this.notificationService.error('Missing "Contact Id"', 'Something went wrong. Please try again.');
          this.fileName = null;
          return;
        }

        this.csvData = result.data as Array<{ [key: string]: string }>;
        const contactIds = this.csvData.map((row) => row['Contact Id']).filter((id) => id);

        if (contactIds.length > 0) {
          this.onFileDataParsed.emit({ fileName: this.fileName ?? '', parsedData: this.csvData });
          this.notificationService.success('update document', 'Document Uploaded');
        } else {
          this.notificationService.error('No contact IDs found', 'Something went wrong. Please try again.');
        }
      },
      error: (error: any) => {
        console.error('Error parsing CSV:', error);
        this.notificationService.error('Error parsing the CSV file', error.message);
      },
    });
  }

  stopPropagation = (event: MouseEvent) => {
    event?.stopPropagation();
  };
  clearSearch() {
    this.searchQuery = '';
  }
  onOutsideClick = () => {
    this.dropdownOpened = false;
    this.searchQuery = '';
  };

  handleButtonClick = (event: MouseEvent) => {
    this.dropdownOpened = !this.dropdownOpened;
  };

  handleItemSelect = (event: MouseEvent, item: DropdownObjectType) => {
    event?.stopPropagation();
    if (this.onItemSelect) {
      this.onItemSelect.emit(item);
    }
    this.dropdownOpened = false;
    this.searchQuery = '';
  };

  getFilters = (): Array<DropdownObjectType> => {
    if (this.searchQuery.length) {
      return this.contentList.filter((item) => item.label.toLowerCase().includes(this.searchQuery.toLowerCase()));
    }
    if (this.contentList) {
      return this.contentList;
    }
    return [];
  };
}
