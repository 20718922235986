<div class="input-wrapper">
  <filter-date-range-picker *ngIf="customInputType == 'date';else otherInput" [singleDatePicker]="singleDatePicker" [showFilterUI]="true"
    customFormat="MMM DD, YYYY" (onDateSelect)="handleDateSelect($event)"></filter-date-range-picker>
  <ng-template #otherInput>
    <input *ngIf="customInputType !== 'disabled';else disabledInput" [type]="getInputType()" [(ngModel)]="inputText"
      (ngModelChange)="handleInputChange($event)" [placeholder]="placeholderText"
      [attr.maxlength]="maxLength !== undefined ? maxLength : null"  (click)="stopPropagation($event)"
      class="custom-input" >
    <ng-template #disabledInput>
      <div class="disabled-input">
        Enter Value
      </div>
    </ng-template>
  </ng-template>

</div>