import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomSelectComponent } from '../../../custom-select/custom-select.component';
import { CustomInputComponent } from '../../../custom-input/custom-input.component';
import { DataClusterFieldComponent } from './data-cluster-field/data-cluster-field.component';
import * as Types from '../../../../shared/types';
import { DisableContent, FILTER_OPERATIONS } from '../../../../shared/constant';
import { FilterService } from '../../../../services/filter.service';

@Component({
  selector: 'cluster-item',
  standalone: true,
  imports: [CustomSelectComponent, CustomInputComponent, CommonModule, DataClusterFieldComponent],
  templateUrl: './cluster-item.component.html',
  styleUrl: './cluster-item.component.scss',
})
export class ClusterItemComponent {
  constructor(
    private filterService: FilterService,
    private cdRef: ChangeDetectorRef
  ) {}
  @Input() openAbove: boolean = false;
  @Input() _filterItemLocal: Types.FilterItemLocal = {} as Types.FilterItemLocal;
  @Input() item: Types.ClusterDataLocal = {} as Types.ClusterDataLocal;
  @Input() last: boolean = false;
  @Output() operationSelected = new EventEmitter<boolean>();
  selectedInputType: Types.CustomInputType = Types.CustomInputType.string;
  operationContent: Array<Types.DropdownObjectType> = FILTER_OPERATIONS.cluster;

  ngOnInit() {}

  handleUpdateFilterItem(keepReference = false, enableApplyButton = false) {
    this.filterService.updateCondition(this._filterItemLocal, keepReference, enableApplyButton);
    this.cdRef.detectChanges();
  }

  handleClusterSelect(data: string, rootCauseDataLocal: Types.ClusterDataLocal) {
    rootCauseDataLocal.selectedCluster = { label: data, value: data };
    this.handleUpdateFilterItem(true, !!rootCauseDataLocal.value.length);
  }

  handleOperationSelect(value: Types.DropdownObjectType, rootCauseDataLocal: Types.ClusterDataLocal) {
    let enableApplyButton = !!rootCauseDataLocal.value?.length || !!this._filterItemLocal.value?.length;
    if (value?.value === DisableContent.isEmpty.value || value?.value === DisableContent.isNotEmpty.value) {
      this.selectedInputType = Types.CustomInputType.disabled;
      enableApplyButton = true;
    } else {
      this.selectedInputType = Types.CustomInputType.string;
    }

    rootCauseDataLocal.selectedOperation = value;
    this.handleUpdateFilterItem(true, enableApplyButton);
  }

  handleInputChange(input: string, rootCauseDataLocal: Types.ClusterDataLocal) {
    rootCauseDataLocal.value = input;
    this.handleUpdateFilterItem(true, !!input.length);
  }

  handleCloseButtonClick(event: MouseEvent, item: Types.ClusterDataLocal) {
    event.stopPropagation();
    if (this._filterItemLocal.clusterData && this._filterItemLocal.clusterData.length > 1) {
      this.filterService.removeClusterItem(item.index, this._filterItemLocal.index);
    }
  }

  handleAddButtonClick(event: MouseEvent, item: Types.ClusterDataLocal) {
    event.stopPropagation();
    this.filterService.addCluster(item.index, this._filterItemLocal.index);
  }
}
