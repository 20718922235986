import { Injectable, signal, WritableSignal } from '@angular/core';

import * as Types from '../../shared/types';
import { InitialDate } from '../../shared/constant';

@Injectable({
  providedIn: 'root',
})
export class DateRangePickerService {
  constructor() {}

  _selectedRangeCalendar = signal<Types.SelectedRangeCalendar>({ ...InitialDate });
  _dateSelected = signal<boolean>(false);
  _dateInitialized = signal<boolean>(false);

  public get selectedRangeCalendar(): WritableSignal<Types.SelectedRangeCalendar> {
    return this._selectedRangeCalendar;
  }

  setSelectedRangeCalendar(obj: Types.SelectedRangeCalendar): void {
    this._selectedRangeCalendar.set(obj);
  }

  public get dateSelected(): WritableSignal<boolean> {
    return this._dateSelected;
  }

  setDateSelected(enabled: boolean): void {
    this._dateSelected.set(enabled);
  }

  public get dateInitialized(): WritableSignal<boolean> {
    return this._dateInitialized;
  }

  setDateInitialized(init: boolean): void {
    this._dateInitialized.set(init);
  }
}
