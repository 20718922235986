import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sentimentClass', standalone: true })
export class SentimentClassPipe implements PipeTransform {
  transform(value: string): string {
    const sentiment = value?.trim().toLowerCase();
    switch (sentiment) {
      case 'positive':
        return 'auto-answer-positive';
      case 'negative':
        return 'auto-answer-negative';
      case 'neutral':
        return 'auto-answer-neutral';
      default:
        return '';
    }
  }
}
