import { Component, computed, EventEmitter, Output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedDataService } from '../../../services/shared-data.service';
import { TabularViewComponent } from '../tabular-view/tabular-view.component';
import { Dashboard, LabelTypes } from '../../../shared/label.types';
import { LabelService } from '../../../services/label.service';
import { OutsideClickDirective } from '../../../directives/outside-click.directive';

@Component({
  selector: 'more-drop-down',
  standalone: true,
  imports: [OutsideClickDirective, CommonModule],
  templateUrl: './more-drop-down.component.html',
  styleUrl: './more-drop-down.component.scss',
})
export class MoreDropDownComponent {
  @Output() handleOnDownload = new EventEmitter<void>();
  protected _dropdownOpened = signal<boolean>(false);
  dashboard: Dashboard = {} as Dashboard;

  constructor(
    private sharedDataService: SharedDataService,
    public labelService: LabelService
  ) { }

  ngOnInit() {
    this.sharedDataService.getToggleValues().subscribe((values) => {
      this._dropdownOpened.set(values.moreOptions);
    });
    this.labelService.getLabelData().subscribe((data: LabelTypes) => {
      this.dashboard = data?.dashboard ?? {};
    });
  }

  toggleMoreDropdown(event: MouseEvent) {
    this.sharedDataService.toggleMore(!this._dropdownOpened());
    event?.stopPropagation();
  }

  onOutsideClick = () => {
    this.sharedDataService.toggleMore(false);
  };

  //download table data csv file
  onDownload() {
    if (this.handleOnDownload) {
      this.handleOnDownload.emit();
    }
  }
}
