<!-- Date-range picker -->
<div class="datepicker-class">
  <div class="row mt-3 mb-3">

    <div class="col-4" style="display: flex; flex-direction: row; position: relative;">
      <div *ngIf="!showFilterUI;else filterUI" class="button-wrapper" [ngStyle]="{ 'width': divWidthTop }">
        <app-button iconUrl="assets/icons/date_range.svg" (onButtonClick)="toggleDropdown()"
          [buttonText]="getDateLabel()" [clicked]="_dropdownOpened()"></app-button>
      </div>
      <ng-template #filterUI>
        <div class="single-date-wrapper" (click)="handleButtonClick($event)">
          <div class="single-date-label">{{selectedDate | truncate: 22}}</div>
          <img src="assets/icons/date_range.svg" alt="">
        </div>
      </ng-template>
      <input [ngStyle]="{ 'width': divWidthBottom }" class="rangepicker-input" type="text" [id]="id"
        class="date-picker-input" readonly ngxDaterangepickerBootstrap [(ngModel)]="selectedRangeCalendarAutoLeft"
        [drops]="dropsDown" [opens]="opensLeft" [locale]="locale" [ranges]="ranges"
        [showCustomRangeLabel]="!showFilterUI" [showCancel]="true" [linkedCalendars]="true" [alwaysShowCalendars]="true"
        [showRangeLabelOnInput]="false" [keepCalendarOpeningWithRange]="true" [closeOnAutoApply]="true"
        [autoApply]="true" [isTooltipDate]="isTooltipDate" [isCustomDate]="isCustomDates"
        [isInvalidDate]="isInvalidDate" (datesUpdated)="datesUpdatedRange($event)"
        (ngModelChange)="datesUpdatedRange($event); loadFetchFunctions()" [singleDatePicker]="singleDatePicker"
        appOutSideClick (outSideClick)="handleOutSideClick()" aria-describedby="rangeCalendarAutoRightDesc" />
    </div>
  </div>
</div>