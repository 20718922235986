import { Pipe, PipeTransform } from '@angular/core';
import { TruncateLimitCustomSelect } from '../shared/constant';

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value = '', limit: number = TruncateLimitCustomSelect): string {
    if (!value) {
      return '';
    }

    return value.length > limit ? value.substring(0, limit) + '...' : value;
  }
}
