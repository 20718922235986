<div class="select-container">
    <div class="select-button" (click)="handleButtonClick($event)">
        <div class="button-text" [ngClass]="{'placeholder': !isOptionSelected, 'selected': isOptionSelected}">
            {{ selectedClusterText }}
        </div>

        <div class="button-icon">
            <img src="assets/icons/keyboard_arrow_down_new.svg">
        </div>
    </div>

    <div class="dropdown-content-container" *ngIf="dropdownOpened" appOutSideClick (outSideClick)="onOutsideClick()">
        <div class="container-header">
            <div class="header-title" *ngIf="listHeader">Select Data Cluster</div>
        </div>
        <div class="option-search-container">
            <input type="text" [(ngModel)]="searchQuery" placeholder="Search" (input)="updateFilteredTopics()"
                (click)="stopPropagation($event)" class="custom-input">
            <span class="close-icon" *ngIf="searchQuery" (click)="clearSearch(); $event.stopPropagation()">
                <img src="../../../assets/icons/close.svg">
            </span>
        </div>

        <div class="columns-list">
            <div class="column-item" *ngFor="let item of filteredTopics; let i = index"
                (click)="handleOptionSelect($event,item)">

                <div class="radio-button">
                    <input type="radio" name="topicSelection" [value]="item.label" [checked]="item.tempIsSelected">
                    <div class="column-name">{{ item.label }}</div>
                </div>

                <div class="item-count">
                    <span class="count">
                        {{ item.count }} items
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>