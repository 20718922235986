<div class="more-wrapper">
  <img src="assets/icons/more_vert.svg" alt="More" (click)="toggleMoreDropdown()">
</div>

<div class="dropdown-content-container" *ngIf="dropdownOpened" appOutSideClick (outSideClick)="onOutsideClick()">
  <div class="save-item" [ngClass]="{ disabled: !savedLabelText.length || !_savedButtonEnabled() }"
    (click)="handleSavePress($event)">
    <div class="item-icon"><img src="assets/icons/save_active.svg" alt="More"></div>
    <div class="item-text">{{filter_save.save}}</div>
  </div>
  <div class="save-item more" (click)="toggleLabelDropdown()" [ngClass]="{ disabled:  !_savedButtonEnabled() }">
    <div class="left-content">
      <div class="item-icon"><img src="assets/icons/save_as.svg" alt="More"></div>
      <div class="item-text">{{filter_save.save_new}}</div>
    </div>
    <div class="item-icon"><img src="assets/icons/arrow_forward_ios.svg" alt="More"></div>
    <div class="filter-label-popup" *ngIf="dropdownOpened && labelPopupOpened" (click)="stopPropagation($event)">
      <div class="label-text"> {{filter_save.filter_label}}<div class="text-asterisk">*</div>
      </div>
      <div class="label-input">
        <custom-input (onInputChange)="handleInputChange($event)">

        </custom-input>
      </div>
      <div class="button-group">
        <div class="btn-all" (click)="handleCancelPress($event)">{{filter_save.cancel}}</div>
        <div class="btn-all" [ngClass]="{ active: labelText.length}" (click)="handleSaveAsNewPress($event)">
          {{filter_save.save}}</div>
      </div>
    </div>
  </div>

  <div class="save-item" (click)="handleClearFilters($event)">
    <div class="item-icon"><img src="assets/icons/delete_outline.svg" alt="More"></div>
    <div class="item-text all">{{filter_save.clear_all_conditions}}</div>
  </div>
</div>