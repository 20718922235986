<div class="select-container">
  <div class="select-button" (click)="handleButtonClick($event)">
    <div class="button-text" [ngClass]="{'placeholder': !isOptionSelected, 'selected': isOptionSelected}">
      {{ selectedOptionText }}
    </div>

    <div class="button-icon">
      <img src="assets/icons/keyboard_arrow_down_new.svg">
    </div>
  </div>

  <div class="dropdown-content-container" *ngIf="dropdownOpened" appOutSideClick (outSideClick)="onOutsideClick()">
    <div class="container-header">
      <div class="header-title">{{ topicsLabels.select_option }} </div>
      <div class="columns-count">{{ topicCount }} {{topicsLabels.options}}</div>
    </div>
    <div class="option-search-container">
      <input type="text" [(ngModel)]="searchQuery" placeholder="Search" (click)="stopPropagation($event)"
        class="custom-input">
      <span class="close-icon" *ngIf="searchQuery" (click)="clearSearch(); $event.stopPropagation()">
        <img src="../../../assets/icons/close.svg">
      </span>
    </div>

    <div class="columns-list">
      <div class="column-item" *ngFor="let item of getPrimaryTopics">
        <div class="column-name">{{ item.label }}</div>
        <div class="toggle">
          <toggle [toggleOn]="item.tempIsSelected" (toggledTo)="onValueChange($event, item)"></toggle>
        </div>
      </div>
    </div>
  </div>
</div>