import { Component, OnInit } from '@angular/core';
import { DATA_ITEMS } from './glossary_details';
import { CommonModule } from '@angular/common';
import { LabelService } from '../../../services/label.service';

export interface DataItem {
  title: string;
  message?: string;
  values?: string[];
}

@Component({
  selector: 'app-glossary',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './glossary.component.html',
  styleUrl: './glossary.component.scss',
})
export class GlossaryComponent {
  constructor(public labelService: LabelService) { }
  dataItems: DataItem[] = [];
  glossary: any = {};

  ngOnInit() {
    this.dataItems = DATA_ITEMS;
    this.labelService.getLabelData().subscribe((data) => {
      this.glossary = data?.glossary ?? {};
    });
  }
}
