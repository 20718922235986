<div class="content-container">
  <div class="select-wrapper">
    <app-data-cluster-field [selectedColumn]="_filterItemLocal.selectedColumn" [inputText]="item?.selectedCluster"
      (onInputChange)="handleClusterSelect($event, item)"[openAbove]="openAbove" >
    </app-data-cluster-field>
  </div>

  <div class="select-wrapper">
    <custom-select [selectedValue]="item?.selectedOperation" [contentList]="operationContent"
      (onItemSelect)="handleOperationSelect($event, item)" [openAbove]="openAbove">
    </custom-select>
  </div>

  <div class="select-wrapper">
    <custom-input [inputText]="item.value" (onInputChange)="handleInputChange($event, item)"
      [customInputType]="selectedInputType"></custom-input>
  </div>

  <div class="close-icon-wrapper" [ngClass]="{ disable: _filterItemLocal.clusterData?.length === 1 }"
    (click)="handleCloseButtonClick($event, item)">
    <img src="assets/icons/close_filter.svg" />
  </div>

  <div *ngIf="last" class="add-icon-wrapper" (click)="handleAddButtonClick($event, item)">
    <img src="assets/icons/add.svg" />
  </div>
</div>