import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'topicClass', standalone: true })
export class TopicClassPipe implements PipeTransform {
  transform(index: number): string {
    switch (index) {
      case 0:
        return 'topic-class-1';
      case 1:
        return 'topic-class-2';
      case 2:
        return 'topic-class-3';
      case 3:
        return 'topic-class-4';
      case 4:
        return 'topic-class-5';
      case 5:
        return 'topic-class-6';
      case 6:
        return 'topic-class-7';
      case 7:
        return 'topic-class-8';
      case 8:
        return 'topic-class-9';
      case 9:
        return 'topic-class-10';
      default:
        return 'default-topic-class';
    }
  }
}
