<div class="select-container">
  <div class="select-button" (click)="handleButtonClick($event)" [matTooltip]="selectedValue?.label | tooltip">
    <div class="button-text">{{selectedValue?.label | truncate}}</div>
    <div class="button-icon">
      <img src="assets/icons/keyboard_arrow_down_new.svg">
    </div>
  </div>

  <div class="dropdown-content-container" *ngIf="dropdownOpened" appOutSideClick (outSideClick)="onOutsideClick()">
    <div class="container-header">
      <div class="header-title" *ngIf="listHeader">{{listHeader}}</div>
    </div>
    <div class="option-search-container">
      <input type="text" [(ngModel)]="searchQuery" placeholder="Search" (click)="stopPropagation($event)"
        class="custom-input">
      <span class="close-icon" *ngIf="searchQuery" (click)="clearSearch(); $event.stopPropagation()"><img
          src="../../../assets/icons/close.svg"></span>
    </div>

    <div class="columns-list">
      <div class="column-item" *ngFor="let item of getFilters()" (click)="handleItemSelect($event, item)">
        <div class="column-name">{{item.label}}</div>
      </div>
    </div>
    <!-- upload document-->
    <button *ngIf="showHiButton" (click)="triggerFileInput()" class="button-style ">
      <img src="assets/icons/file_upload.svg" class="file-upload-icon">
      {{ filters.upload_document }}
    </button>
    <input type="file" #fileInput (change)="handleFileSelected($event)" style="display: none;" accept=".csv" />
  </div>
</div>