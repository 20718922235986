import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OutsideClickDirective } from '../../directives/outside-click.directive';
import { CommonService } from '../../services/common.service';
import { FilterService } from '../../services/filter.service';
import * as Types from '../../shared/types';

@Component({
  selector: 'app-data-cluster-field',
  standalone: true,
  imports: [CommonModule, FormsModule, OutsideClickDirective],
  templateUrl: './data-cluster-field.component.html',
  styleUrls: ['./data-cluster-field.component.scss'],
})
export class DataClusterFieldComponent {
  @Input() inputText: Types.DropdownObjectType | undefined;
  @Input() selectedColumn: Types.DropdownObjectType | undefined;
  @Output() onInputChange = new EventEmitter<string>();

  selectedClusterText: string = 'Select Cluster';
  isOptionSelected = false;
  dropdownOpened: boolean = false;
  searchQuery: string = '';
  listHeader = true;
  filteredTopics: { label: string; tempIsSelected: boolean; count: number }[] = [];
  allTopics: { label: string; tempIsSelected: boolean }[] = []; // Initialize topics here
  clusterCount: number = 0;
  myFilterListForAPI: { [key: string]: string[] } = {};
  filterBody: any;
  filterListPrimaryTopics: Types.ClusterObject[] = [];
  filters: { label: string; tempIsSelected: boolean; count: number }[] = [];
  primaryTopics: { label: string; tempIsSelected: boolean; count: number }[] = [];

  constructor(
    public commonService: CommonService,
    public filterService: FilterService
  ) { }

  ngOnInit() {
    this.filterService.getAllFilters().subscribe((filters) => {
      if (this.selectedColumn?.value === 'RootCause') {
        this.filterListPrimaryTopics = filters.filters.RootCauseClustered;
      } else {
        this.filterListPrimaryTopics = filters.filters.CustomerPainPointClustered;
      }
      this.updateFiltersWithPrimaryTopics();
    });

    if (this.inputText) {
      this.handleOptionSelect(undefined, { label: this.inputText.label });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedColumn']?.currentValue) {
      const filters = this.filterService.getAllFiltersCurrentValue();
      if (this.selectedColumn?.value === 'RootCause') {
        this.filterListPrimaryTopics = filters.filters.RootCauseClustered;
      } else {
        this.filterListPrimaryTopics = filters.filters.CustomerPainPointClustered;
      }
    }
  }

  updateFiltersWithPrimaryTopics(): void {
    this.filters = this.filterListPrimaryTopics
      .filter((topic) => topic.cluster_name.trim() !== '')
      .map((topic) => ({
        label: topic.cluster_name,
        tempIsSelected: false,
        count: topic.original_count,
      }));
    this.primaryTopics = this.filters;
    this.updateFilteredTopics();
  }

  handleButtonClick(event: Event): void {
    this.dropdownOpened = !this.dropdownOpened;
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  clearSearch(): void {
    this.searchQuery = '';
    this.updateFilteredTopics();
  }

  onOutsideClick(): void {
    this.dropdownOpened = false;
  }

  updateFilteredTopics(): void {
    const query = this.searchQuery.toLowerCase();
    this.filteredTopics = this.primaryTopics.filter((topic) => topic.label.toLowerCase().includes(query));
  }

  handleOptionSelect(event = {} as Event, item: { label: string }): void {
    this.selectedClusterText = item.label;
    this.isOptionSelected = true;
    this.dropdownOpened = false;
    this.filteredTopics.forEach((topic) => (topic.tempIsSelected = topic === item));
    if (this.onInputChange) {
      this.onInputChange.emit(item.label);
    }
    event?.stopPropagation();
  }
}
