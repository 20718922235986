<div class="login">
  <div class="loginLeft">
    <div class="imageArt">
      <img src="../../assets/artwork.svg" />
    </div>
    <div class="UpperP">{{login_data.login_title}}</div>

    <div class="LowerP">
      {{login_data.login_subtitle}}
    </div>
  </div>
  <div class="loginRight">
    <div class="loginButtons">
      <div class="care"><img src="../../assets/logo.svg" /></div>
      <div class="loginBtn" (click)="login()" id="loginWithAD">
        <button>{{login_data.login_button_title}}</button>
      </div>
    </div>

    <div class="footer">
      <span class="powered-by">{{login_data.powered_by}}</span><img src="../../assets/rozie-footer.svg" />
    </div>
  </div>
</div>