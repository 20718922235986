import dayjs from 'dayjs';

export interface SentimentCountsData {
  from: string;
  to: string;
  weight: number;
}

export interface ConversationOverview {
  'Average Duration': string | 'NA';
  'Issue Resolution Rate': number | 'NA';
  'Overall Sentiment': string | 'NA';
  'Primary Topics': number | 'NA';
  Reason: number | 'NA';
  'Root Cause': number | 'NA';
  'Total Conversations': number | 'NA';
}

export interface ChartData {
  type: 'area' | 'line' | 'bar' | 'column' | 'pie' | 'scatter';
  name: string;
  data: number[];
}

export interface ChartDataSankey {
  data: any[];
  custom?: {
    customLabels?: Highcharts.SVGElement[];
  };
}

export interface ChartDataBarFormat {
  type: 'area' | 'line' | 'bar' | 'column' | 'pie' | 'scatter';
  name: string;
  data: number[];
}

export interface SentimentRecord {
  CustomerSentimentAtStart: string;
  CustomerSentimentAtEnd: string;
}

export interface Contact {
  ContactId: string;
  Date: string;
  Intent: string;
  PrimaryTopic: string;
  Summary: string;
  CallerType: string;
  JourneyMoment: string;
  CustomerSentimentAtStart: string;
  CustomerSentimentAtEnd: string;
  CallResolution: string;
  Topics: string;
  RootCause: string;
}

export interface PeriodicElement {
  ContactId: string;
  Date: string;
  Intent: string;
  PrimaryTopic: string;
  Summary: string;
  CallerType: string;
  JourneyMoment: string;
  CustomerSentimentAtStart: string;
  CustomerSentimentAtEnd: string;
  CallResolution: string;
  Topics: string;
  RootCause: string;
}

export interface CsvExportResponse {
  URL: string;
  ready_to_download: boolean;
}

export interface PrimaryTopicPercentageData {
  series?: ChartData[];
  dates?: Array<string[]>;
  message?: string;
}

export interface Overview {
  [key: string]: any; // Allow any string key
  'Total Conversations': number;
  'Primary Topics': number;
  Reason: number;
  'Root Cause': number;
  'Customer Pain Points': number;
  'Overall Sentiment': string;
  'Issue Resolution Rate': number;
  'Average Duration': string;
}

export interface ClusterObject {
  cluster_name: string;
  original_count: number;
}

export interface DataFilters {
  filters: {
    [key: string]: string[] | ClusterObject[];
    primarytopic: string[];
    callresolution: string[];
    callertype: string[];
    customersentimentatstart: string[];
    customersentimentatend: string[];
    journeymoment: string[];
    intent: string[];
    RootCauseClustered: ClusterObject[];
    Agent_Username: string[];
    Attributes_authenticationStatus: string[];
    Attributes_tier: string[];
    InitiationMethod: string[];
    Queue_Name: string[];
    CustomerPainPointClustered: ClusterObject[];
    Agent_AgentInteractionDuration: string[];
    TopicsClustered: string[];
  };
}

export interface FilterOption {
  filterId: string;
  name: string;
  isSelected: boolean;
  tempIsSelected?: boolean;
  isFiltered?: boolean;
  filterText?: string;
}

export interface Filter {
  options: FilterOption[];
  allSelected: boolean;
  tempAllSelected?: boolean;
}

export interface FilterGroup {
  name: string;
  showName: string;
  filters: Filter;
}

export interface DateFilterOption {
  d_id: number;
  name: string;
}

export interface TableViewBody {
  start_date?: string;
  end_date?: string;
  filters: FilterRequest[];
}

export interface TableHeaderType {
  name: string;
  key: string;
  icon?: string;
}

export interface SelectedRangeCalendar {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  label?: string;
}

export interface RawTableType {
  conversations: Conversation[];
  total_count: number;
}

export interface Conversation {
  ConversationId: string;
  ContactId: string;
  ContactTimestamp: Date;
  Insights: Insights;
  ContactAttributes: ContactAttributes;
}

export interface ClusterData {
  index: number;
  selectedCluster?: DropdownObjectType;
  selectedOperation?: DropdownObjectType;
  value: string;
}

export interface ClusterDataLocal {
  index: number;
  selectedCluster?: DropdownObjectType;
  selectedOperation?: DropdownObjectType;
  value: string;
}

export interface FilterCondition {
  index: number;
  operator?: string | null;
  selectedColumn?: DropdownObjectType;
  selectedOperation?: DropdownObjectType;
  value: string | string[];
  isCluster: boolean;
  clusterData: ClusterData[];
}

export enum ColumnTypes {
  string = 'string',
  integer = 'integer',
  array = 'array',
  singleArray = 'singleArray',
  date = 'date',
}

export enum CustomInputType {
  string = ColumnTypes.string,
  number = ColumnTypes.integer,
  array = ColumnTypes.array,
  singleArray = ColumnTypes.singleArray,
  date = ColumnTypes.date,
  disabled = 'disabled',
}
// enum Color {
//   Red = 'RED',
//   Green = 'GREEN',
// }

// // Create a new enum by combining with the original enum
// enum ExtendedColor {
//   ...Color, // Inherit values from Color
//   Blue = 'BLUE',  // Add new value
//   Yellow = 'YELLOW', // Add another new value
// }

export interface ColumnPreferenceType {
  column_data_type: string;
  column_label: string;
  column_name: string;
  column_number: number;
  column_visibility: boolean;
}

export interface ColumnHeaders {
  name: string;
  key: string;
  type: string;
  number: number;
  visibility: boolean;
}

export interface DropDownToggleValues {
  hideFields: boolean;
  filter: boolean;
  group: boolean;
  sort: boolean;
  date: boolean;
  moreOptions: boolean;
}

export interface DropdownObjectType {
  value: string;
  label: string;
  type?: ColumnTypes;
}

export interface RequestType {
  start_date: Date;
  end_date: Date;
  filters: FilterRequest[];
}

export interface FilterRequest {
  logicalOperator: string;
  columnName: string;
  operationType: string;
  value: string | string[];
  isClusterFilter: boolean;
  clusterFilters: ClusterFilter[];
}

export interface ClusterFilter {
  clusterName: string;
  clusterValue: string;
  columnName: string;
  operationType: string;
  columnValue: string;
}

export interface FilterItemLocal {
  index: number;
  operator: DropdownObjectType | null;
  selectedColumn: DropdownObjectType | undefined;
  selectedOperation: DropdownObjectType | undefined;
  value: string | string[];
  isCluster: boolean;
  clusterData?: ClusterDataLocal[];
}

export interface FilterSaveRequest {
  filter: {
    filter: FilterRequest[];
  };
  tag: string;
}

export interface FilterDeleteRequest {
  tag: string;
}

export interface FilterListResponse extends FilterSaveRequest {
  updated_at: string;
}

export interface ContactAttributes {
  Agent_ConnectedToAgentTimestamp: string | null;
  Agent_AgentInteractionDuration: string | null;
  Queue_Name: string | null;
  InitiationMethod: string | null;
  Attributes_tier: string | null;
  Attributes_authenticationStatus: string | null;
  Agent_Username: string | null;
  CustomerUtteranceCount: number | null;
  AgentUtteranceCount: number | null;
  AgentConnectionAttempts: number | null;
  Agent_AfterContactWorkDuration: number | null;
  Agent_AfterContactWorkEndTimestamp: Date | null;
  Agent_AfterContactWorkStartTimestamp: Date | null;
  Agent_CustomerHoldDuration: number | null;
  Agent_HierarchyGroups_Level1_GroupName: string | null;
  Agent_HierarchyGroups_Level2_GroupName: string | null;
  Agent_HierarchyGroups_Level3_GroupName: string | null;
  Agent_HierarchyGroups_Level4_GroupName: string | null;
  Agent_HierarchyGroups_Level5_GroupName: string | null;
  Agent_LongestHoldDuration: number | null;
  Agent_NumberOfHolds: number | null;
  Agent_RoutingProfile_Name: string | null;
  Agent_StateTransitions: string | null;
  AnsweringMachineDetectionStatus: string | null;
  Attributes_AeroplanNumber_IT_EN: string | null;
  Attributes_CallBackPhoneNumber_IT_EN: string | null;
  Attributes_CallBackSelection_IT_EN: string | null;
  Attributes_PCI_Compliance: string | null;
  Attributes_PreferredLanguage_IT_EN: string | null;
  Attributes__LOB: string | null;
  Attributes_acCallBlockStatus: string | null;
  Attributes_actualEWT: string | null;
  Attributes_authPinStatus: string | null;
  Attributes_authenticationStatus_IT_EN: string | null;
  Attributes_basicQueue: string | null;
  Attributes_branch: string | null;
  Attributes_broadCastFlag: string | null;
  Attributes_callBackPhoneNumber: string | null;
  Attributes_callbackDeQueuedTime: string | null;
  Attributes_callbackEnQueuedTime: string | null;
  Attributes_callbackOrignalContactId: string | null;
  Attributes_callbackQueueName: string | null;
  Attributes_callbackfinalcontactid: string | null;
  Attributes_callbackstatus: string | null;
  Attributes_callbackstatusType: string | null;
  Attributes_callerQualifyValue: string | null;
  Attributes_countryOfOrigin: string | null;
  Attributes_enableExtensionCallRecording: string | null;
  Attributes_getAeroplanNo: string | null;
  Attributes_getCountryCode: string | null;
  Attributes_getEmergency: string | null;
  Attributes_getHoldTime: string | null;
  Attributes_getHoliday: string | null;
  Attributes_getLanguage: string | null;
  Attributes_getPin: string | null;
  Attributes_getPriority: string | null;
  Attributes_invalidInput_mainMenu: string | null;
  Attributes_isCCOpen: string | null;
  Attributes_mainMenuSelection_IT: string | null;
  Attributes_numberBlocked: string | null;
  Attributes_phoneNumberStatus: string | null;
  Attributes_pinNumberEntered_IT: string | null;
  Attributes_queueBlockedStatus: string | null;
  Attributes_regionCode: string | null;
  Attributes_returnModule: string | null;
  Attributes_returningModule: string | null;
  Attributes_statusCode: string | null;
  Attributes_triggerCallRecOptions: string | null;
  Attributes_userLanguage: string | null;
  Attributes_waitTimeParameter: string | null;
  Campaign_CampaignId: string | null;
  Channel: string | null;
  ConnectedToSystemTimestamp: Date | null;
  ContactDetails: string | null;
  CustomerEndpoint_Address: string | null;
  CustomerEndpoint_Type: string | null;
  DisconnectReason: string | null;
  DisconnectTimestamp: Date | null;
  InitialContactId: string | null;
  InitiationTimestamp: Date | null;
  LastUpdateTimestamp: Date | null;
  MediaStreams_0_Type: string | null;
  NextContactId: string | null;
  PreviousContactId: string | null;
  Queue_DequeueTimestamp: Date | null;
  Queue_Duration: number | null;
  Queue_EnqueueTimestamp: Date | null;
  Recording_DeletionReason: string | null;
  Recording_Status: string | null;
  Recording_Type: string | null;
  Recordings_0_DeletionReason: string | null;
  Recordings_0_MediaStreamType: string | null;
  Recordings_0_ParticipantType: string | null;
  Recordings_0_StartTimestamp: string | null;
  Recordings_0_Status: string | null;
  Recordings_0_StopTimestamp: string | null;
  ScheduledTimestamp: string | null;
  SystemEndpoint_Address: string | null;
  SystemEndpoint_Type: string | null;
  TransferCompletedTimestamp: string | null;
  TransferredToEndpoint: string | null;
  VoiceIdResult: string | null;
}

export interface Insights {
  Intent: string;
  Topics: string[];
  Summary: string;
  RootCause: String;
  CustomerPainPoint: string;
  CallerType: string;
  PrimaryTopic: string;
  JourneyMoment: string;
  CallResolution: string;
  CustomerSentimentAtEnd: CustomerSentimentAtEnd;
  CustomerSentimentAtStart: CustomerSentimentAtStart;
}

export enum CustomerSentimentAtEnd {
  Na = 'NA',
  Positive = 'Positive',
  Negative = 'Negative',
  Neutral = 'Neutral',
}

export enum CustomerSentimentAtStart {
  Na = 'NA',
  Positive = 'Positive',
  Negative = 'Negative',
  Neutral = 'Neutral',
}

export enum AlertTypes {
  Error,
  Info,
  Success,
  Processing,
}
export interface DataType {
  config: any;
  status: AlertTypes;
  title: string;
  message: string;
}
