import { Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as Types from '../shared/types';
import { formatOptions } from '../shared/appUtil';
import * as Constants from '../shared/constant';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  constructor() {
    this._conversationOverview$ = new BehaviorSubject({} as Types.Overview);
    this._dateRange$ = new BehaviorSubject({ startAPIDate: '', endAPIDate: '' });
    this._selectedColumns$ = new BehaviorSubject(['']);
    this._columnNames$ = new BehaviorSubject([] as Types.ColumnHeaders[]);

    this._dropDownToggleValues$ = new BehaviorSubject(Constants.InitialToggleValues as Types.DropDownToggleValues);
  }

  private _conversationOverview$: BehaviorSubject<Types.Overview>;
  private _dateRange$: BehaviorSubject<{ startAPIDate: string; endAPIDate: string }>;
  private _selectedColumns$: BehaviorSubject<string[]>;
  private _columnNames$: BehaviorSubject<Types.ColumnHeaders[]>;
  private _dropDownToggleValues$: BehaviorSubject<Types.DropDownToggleValues>;
  public apiData: any;
  public apiTotalRow: any;
  hiddenColumns: string[] = [];
  hiddenColumnsKey = 'hidden_columns';
  columnsFilterGroup: Types.FilterGroup = {
    name: 'ColumnsVisible',
    showName: 'Select Saved Filter',
    filters: {
      allSelected: false,
      tempAllSelected: false,
      options: formatOptions([]),
    },
  };

  updateFilterOptionsBasedOnSelection() {
    let selectedColumns: string[] = [];
    this.columnsFilterGroup.filters.options.forEach((option) => {
      option.isSelected = !this.hiddenColumns.includes(option.name);
      option.tempIsSelected = option.isSelected;
      if (!this.hiddenColumns.includes(option.name)) {
        selectedColumns.push(option.name);
      }
    });
    this._selectedColumns$.next(selectedColumns);
  }

  handlePopulateFilteredData = () => {
    let tempHiddenColumns: string[] = [];
    let tempSelectedColumns: string[] = [];

    this.columnsFilterGroup.filters.options.forEach((option) => {
      if (option.isSelected) {
        tempSelectedColumns.push(option.name);
      } else {
        tempHiddenColumns.push(option.name);
      }
    });

    // Store locally, batch update after processing
    this.hiddenColumns = tempHiddenColumns;
    localStorage.setItem(this.hiddenColumnsKey, JSON.stringify(tempHiddenColumns));

    if (!tempHiddenColumns.length || tempHiddenColumns.length === this.columnsFilterGroup.filters.options.length) {
      localStorage.removeItem(this.hiddenColumnsKey);
    }

    // Batch update of selected columns
    this._selectedColumns$.next(tempSelectedColumns);
  };

  handleHideAll = () => {
    let tempHiddenColumns: string[] = [];
    this._selectedColumns$.next([]);
    this.columnsFilterGroup.filters.options.forEach((option) => {
      option.isSelected = false;
      option.tempIsSelected = false;
      tempHiddenColumns.push(option.name);
    });
    this.hiddenColumns = tempHiddenColumns;
    localStorage.setItem(this.hiddenColumnsKey, JSON.stringify(tempHiddenColumns));
  };

  handleShowAll = () => {
    let tempSelectedColumns: string[] = [];
    this.hiddenColumns = [];
    this.columnsFilterGroup.filters.options.forEach((option) => {
      option.isSelected = true;
      option.tempIsSelected = true;
      tempSelectedColumns.push(option.name);
    });
    this._selectedColumns$.next(tempSelectedColumns);
    localStorage.removeItem(this.hiddenColumnsKey);
  };

  setSelectedColumns() {
    let temp = localStorage.getItem(this.hiddenColumnsKey);
    if (temp) {
      this.hiddenColumns = JSON.parse(temp);
      this.updateFilterOptionsBasedOnSelection();
    } else {
      this.hiddenColumns = [];
      let selectedColumns = this._columnNames$.value.map((header) => header.name);
      this._selectedColumns$.next(selectedColumns);
    }
  }

  setColumnNames = (columnNames: Types.ColumnHeaders[]) => {
    this.columnsFilterGroup.filters.options = formatOptions(columnNames);
    this._columnNames$.next(columnNames);
  };

  getColumnNames = () => {
    return this._columnNames$;
  };

  getColumnNamesValue = () => {
    return this._columnNames$.value;
  };

  getSelectedColumns = () => {
    return this._selectedColumns$;
  };

  getColumnsFilterGroup = () => {
    return this.columnsFilterGroup;
  };

  setDateRange(data: { startAPIDate: string; endAPIDate: string }) {
    this._dateRange$.next(data);
  }

  getDateRange() {
    return this._dateRange$.asObservable();
  }

  setConversationOverview(data: Types.Overview) {
    this._conversationOverview$.next(data);
  }

  getConversationOverview() {
    return this._conversationOverview$.asObservable();
  }

  setApiData(data: any): void {
    this.apiData = data;
  }

  getApiData(): any {
    return this.apiData;
  }

  setApiTotalRow(totalRow: any): void {
    this.apiTotalRow = totalRow;
  }

  getApiTotalRow(): any {
    return this.apiTotalRow;
  }

  getToggleValues() {
    return this._dropDownToggleValues$;
  }

  toggleHide(value: boolean) {
    this._dropDownToggleValues$.next({
      ...Constants.InitialToggleValues,
      hideFields: value,
    });
  }

  toggleFilter(value: boolean) {
    this._dropDownToggleValues$.next({
      ...Constants.InitialToggleValues,
      filter: value,
    });
  }

  toggleDate(value: boolean) {
    this._dropDownToggleValues$.next({
      ...Constants.InitialToggleValues,
      date: value,
    });
  }

  toggleMore(value: boolean) {
    this._dropDownToggleValues$.next({
      ...Constants.InitialToggleValues,
      moreOptions: value,
    });
  }
}
