import { Directive, ElementRef, Output, EventEmitter, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appOutSideClick]',
  standalone: true,
})
export class OutsideClickDirective {
  @Output() outSideClick: EventEmitter<void> = new EventEmitter();
  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) {}

  private listener: (() => void) | undefined;
  // Specify the class you want to check on the event target
  private excludeClasses: string[] = [
    'cdk-overlay-backdrop',
    'column-item',
    'column-name',
    'container-header-custom',
    'header-title',
    'mat-mdc-menu-content',
    'container-header',
    'columns-count',
    'columns-list',
    'option-search-container',
    'dropdown-content-container',
    'container-header-data',
    'count',
    'custom-file-input',
    'custom-button-style',
    'file-upload-icon',
    'icon',
    'close-icon',
  ]; // Add your class names here

  // Execute this function when click outside of the dropdown-container
  onDocumentClick = (event: Event) => {
    const target = event.target as HTMLElement;

    // If the event target has the exclude class, return early (do nothing)
    if (this.excludeClasses.some((cls) => target.classList.contains(cls))) {
      return;
    }

    if (!this.element.nativeElement.parentElement.contains(event.target)) {
      this.outSideClick.emit();
    }
  };

  //Add the listener when the dropdown component is rendered
  ngOnInit(): void {
    this.listener = this.renderer.listen('document', 'click', this.onDocumentClick);
  }

  //To reduce unnecessary memory leaks you need to use the clean-up
  ngOnDestroy(): void {
    if (this.listener) {
      this.listener();
    }
  }
}
