import { Pipe, PipeTransform } from '@angular/core';
import { TruncateLimitCustomSelect } from '../../shared/constant';

@Pipe({
  name: 'tooltip',
  standalone: true,
})
export class TooltipPipe implements PipeTransform {
  transform(label: string | undefined): string {
    const limit = TruncateLimitCustomSelect;
    return label && label.length > limit ? label : '';
  }
}
