import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {
  NgxDaterangepickerBootstrapComponent,
  NgxDaterangepickerBootstrapDirective,
  provideDaterangepickerLocale,
} from 'ngx-daterangepicker-bootstrap';

import * as Types from '../../shared/types';
import dayjs, { Dayjs } from 'dayjs';
import * as Constants from '../../shared/constant';
import { SharedDataService } from '../../services/shared-data.service';
import { CommonService } from '../../services/common.service';
import { ButtonComponent } from '../button/button.component';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { OutsideClickDirective } from '../../directives/outside-click.directive';

interface DateObject extends Object {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  label: string;
}

@Component({
  selector: 'filter-date-range-picker',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    NgxDaterangepickerBootstrapDirective,
    NgxDaterangepickerBootstrapComponent,
    ButtonComponent,
    TruncatePipe,
    OutsideClickDirective,
  ],
  providers: [
    {
      provide: provideDaterangepickerLocale,
      useFactory: () =>
        provideDaterangepickerLocale({
          separator: ' - ',
          applyLabel: 'Okay3',
        }),
    },
  ],
  templateUrl: './filter-date-range-picker.component.html',
  styleUrl: './filter-date-range-picker.component.scss',
})
export class FilterDaterangePickerComponent {
  @ViewChild(NgxDaterangepickerBootstrapDirective, { static: false }) pickerDirective1:
    | NgxDaterangepickerBootstrapDirective
    | undefined;
  @Input() singleDatePicker: boolean = false;
  @Input() showFilterUI: boolean = false;
  @Input() customFormat: string = 'YYYY-MM-DD';
  @Output() onDateSelect = new EventEmitter<string | string[]>();

  id = Math.random();

  divWidthTop: string = '70px'; // Default width
  divWidthBottom: string = '65px'; // Default width
  // dropdownOpened: boolean = false;
  protected _dropdownOpened = signal<boolean>(false);

  // DateRange Picker Start
  dropsDown = 'down';
  dropsUp = 'up';
  opensRight = 'right';
  opensCenter = 'center';
  opensLeft = 'left';
  selectedRangeCalendarAutoLeft: Types.SelectedRangeCalendar = {} as Types.SelectedRangeCalendar;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  invalidDates: Dayjs[] = [];
  startAPIDate: string | undefined;
  endAPIDate: string | undefined;
  selectedDate: string | undefined;
  // isSelectButtonEnabled: boolean[] = [];
  // noDataFoundFlag: Boolean = false;
  referenceDate = dayjs().subtract(1, 'day');
  ranges = {
    'Last 7 days': [this.referenceDate.subtract(6, 'days').startOf('day'), this.referenceDate.endOf('day')],
    'This month': [this.referenceDate.startOf('month'), this.referenceDate.endOf('month')],
    'Last 3 months': [
      this.referenceDate.subtract(3, 'months').startOf('month'),
      this.referenceDate.subtract(1, 'month').endOf('month'),
    ],
    'Last 6 months': [
      this.referenceDate.subtract(6, 'months').startOf('month'),
      this.referenceDate.subtract(1, 'month').endOf('month'),
    ],
  } as any;
  localeTime = Constants.LocaleTime;
  locale = Constants.Locale;
  tooltips = [
    { date: dayjs(), text: '' },
    { date: dayjs().add(2, 'days'), text: '' },
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    public sharedDataService: SharedDataService,
    public commonService: CommonService
    // public labelService: LabelService,
  ) {
    this.datepickerFunction();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.datepickerFunction();
  }

  ngOnInit() {
    if (this.singleDatePicker || this.showFilterUI) {
      this.ranges = {};
    }
    this.datepickerFunction();
  }

  ngOnDestroy() {
    this.pickerDirective1?.hide();
    this.cdr.detectChanges();
  }

  datepickerFunction() {
    if (this.singleDatePicker) {
      this.selectedRangeCalendarAutoLeft = {
        startDate: dayjs(),
        endDate: dayjs(),
      };
      this.divWidthBottom = '172px';
    }
    if (this.showFilterUI) {
      this.selectedRangeCalendarAutoLeft = {
        startDate: dayjs(),
        endDate: dayjs(),
      };
      this.divWidthBottom = '172px';
    } else {
      this.selectedRangeCalendarAutoLeft = {
        startDate: this.ranges['Last 7 days'][0],
        endDate: this.ranges['Last 7 days'][1],
        label: 'Last 7 Days',
      };
    }
  }

  isInvalidDate = (m: Dayjs) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  isCustomDates = (date: Dayjs) => {
    return date.month() === 0 || date.month() === 6 ? 'mycustomdate' : false;
  };

  isTooltipDate = (m: Dayjs) => {
    const tooltip = this.tooltips.find((tt) => tt.date.isSame(m, 'day'));
    return tooltip ? tooltip.text : false;
  };

  toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase());
  };

  datesUpdatedRange($event: object) {
    const updatedRangeObject = $event as DateObject;
    // this._dropdownOpened.set(false);

    if (this.singleDatePicker) {
      this.selectedDate = updatedRangeObject?.startDate?.format(this.customFormat);
      const apiDate = updatedRangeObject?.startDate?.format('YYYY-MM-DD');
      if (this.onDateSelect) {
        this.onDateSelect.emit(apiDate);
      }
    } else if (updatedRangeObject?.label) {
      this.selectedRangeCalendarAutoLeft.label = this.toTitleCase(updatedRangeObject.label);

      if (this.selectedRangeCalendarAutoLeft.label === 'Custom Range') {
        this.selectedRangeCalendarAutoLeft.label = `${this.selectedRangeCalendarAutoLeft.startDate.format('DD MMM YYYY')} - ${this.selectedRangeCalendarAutoLeft.endDate.format('DD MMM YYYY')}`;
      }

      if (this.selectedRangeCalendarAutoLeft.label.length < 12) {
        this.divWidthTop = '120px';
        this.divWidthBottom = '114px';
      } else if (this.selectedRangeCalendarAutoLeft.label.length < 15) {
        this.divWidthTop = '135px';
        this.divWidthBottom = '129px';
      } else {
        this.divWidthTop = '223px';
        this.divWidthBottom = '217px';
      }
      this.startAPIDate = this.selectedRangeCalendarAutoLeft.startDate.format('YYYY-MM-DD');
      this.endAPIDate = this.selectedRangeCalendarAutoLeft.endDate.format('YYYY-MM-DD');

      this.commonService.historyPageNumber = 1;
      this.commonService.setLoaderFlag(true);
    } else {
      // filter date range picker logic
      if (this.showFilterUI) {
        this.selectedDate = `${updatedRangeObject?.startDate?.format(this.customFormat)} - ${updatedRangeObject?.endDate?.format(this.customFormat)}`;
        const apiDate = [
          updatedRangeObject?.startDate?.format('YYYY-MM-DD'),
          updatedRangeObject?.endDate?.format('YYYY-MM-DD'),
        ];
        if (this.onDateSelect) {
          this.onDateSelect.emit(apiDate);
        }
      }
    }
  }

  makeLoaderTrue(event: MouseEvent) {
    this.commonService.setLoaderFlag(true);
    this.cdr.detectChanges();
  }
  // DateRange Picker End

  //load plots functions
  loadFetchFunctions() {
    this.cdr.detectChanges();
  }

  openDatepicker() {}

  toggleDropdown = () => {
    if (this.pickerDirective1) {
      this.pickerDirective1.toggle();
      this.cdr.detectChanges();
      if (!this.showFilterUI) {
        // this.sharedDataService.toggleDate(!this._dropdownOpened());
      }
      // else if(this.pickerDirective2){
      //   this.pickerDirective2.toggle();
      // }
    }
  };

  handleOutSideClick = () => {
    // this._dropdownOpened.set(false);
    this.pickerDirective1?.hide();
    this.cdr.detectChanges();
  };

  handleButtonClick = (event: MouseEvent) => {
    this.toggleDropdown();
    event?.stopPropagation();
  };

  getDateLabel = () => {
    return this.selectedRangeCalendarAutoLeft.label ?? '';
  };
}
