import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmPopupComponent } from '../components/confirm-popup/confirm-popup.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmPopupService {
  constructor(private dialog: MatDialog) {}

  openConfirmDialog(data: any): Observable<any> {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      autoFocus: false,
      width: '640px',
      panelClass: 'dialog-panel',
      backdropClass: 'backdrop-background',
      disableClose: true,
      data,
    });

    return dialogRef.afterClosed();
  }
}
