<div class="field-container">
  <app-button [buttonText]="buttonTitle" iconUrl="assets/icons/outlined.svg" (onButtonClick)="toggleDropdown()"
    [clicked]="_dropdownOpened()"></app-button>

  <div class="dropdown-content-container" *ngIf="_dropdownOpened()" appOutSideClick (outSideClick)="onOutsideClick()">
    <div class="container-header">
      <div class="header-title">{{dropdown.select_fields}}</div>
      <div class="columns-count">{{getColumnCount()}}</div>
    </div>
    <div class="option-search-container">
      <input type="text" [(ngModel)]="searchQuery" placeholder="Search" (click)="stopPropagation($event)"
        class="custom-input">
      <span class="close-icon" *ngIf="searchQuery" (click)="clearSearch(); $event.stopPropagation()"><img
          src="../../../assets/icons/close.svg"></span>
    </div>

    <div class="columns-list">
      <div class="column-item" *ngFor="let item of getFilters()">
        <div class="column-name">{{item.name}}</div>
        <div class="toggle">
          <toggle [toggleOn]="item.tempIsSelected ?? false" (toggledTo)="onValueChange($event, item)"></toggle>
        </div>
      </div>
    </div>

    <div *ngIf="searchQuery.length === 0" class="button-group">
      <div class="btn-all" (click)="handleHideAll()">{{dropdown.hide_all}}</div>
      <div class="btn-all" (click)="handleShowAll()"> {{dropdown.show_all}}</div>
    </div>
  </div>


</div>