import { Component, Input, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSelectComponent } from '../../custom-select/custom-select.component';
import { CustomInputComponent } from '../../custom-input/custom-input.component';
import * as Types from '../../../shared/types';
import { transformTableHeaders } from '../../../shared/appUtil';
import { ButtonComponent } from '../../button/button.component';
import { FilterService } from '../../../services/filter.service';
import { FILTER_OPERATIONS } from '../../../shared/constant';
import { DataClusterFieldComponent } from '../../data-cluster-field/data-cluster-field.component';
import { SharedDataService } from '../../../services/shared-data.service';
import { LabelService } from '../../../services/label.service';
import { Filters, LabelTypes } from '../../../shared/label.types';

@Component({
  selector: 'filter-cluster',
  standalone: true,
  imports: [CustomSelectComponent, CustomInputComponent, ButtonComponent, CommonModule, DataClusterFieldComponent],
  templateUrl: './filter-cluster.component.html',
  styleUrls: ['./filter-cluster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterClusterComponent implements OnInit {
  @Input() filterItem: Types.FilterCondition = {} as Types.FilterCondition;

  _filterItemLocal: Types.FilterItemLocal = {} as Types.FilterItemLocal;
  columnNames: Array<Types.DropdownObjectType> = [];
  operationContent: Array<Types.DropdownObjectType> = FILTER_OPERATIONS.cluster;

  filters: Filters = {} as Filters;

  constructor(
    private filterService: FilterService,
    private sharedDataService: SharedDataService,
    private labelService: LabelService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.labelService.getLabelData().subscribe((data: LabelTypes) => {
      this.filters = data?.filter ?? {};
      this.cdRef.markForCheck();
    });

    this.sharedDataService.getColumnNames().subscribe((names) => {
      this.columnNames = transformTableHeaders(names);
      this.cdRef.markForCheck();
    });

    this.initializeFilterItemLocal();
  }

  initializeFilterItemLocal() {
    this._filterItemLocal = {
      index: this.filterItem.index,
      operator: this.filterItem.operator ? { value: this.filterItem.operator, label: this.filterItem.operator } : null,
      selectedColumn: this.filterItem.selectedColumn,
      selectedOperation: this.filterItem.selectedOperation,
      value: this.filterItem.value,
      isCluster: this.filterItem.isCluster,
      clusterData: this.convertToRootCauseDataLocal(this.filterItem.clusterData),
    };
  }

  convertToRootCauseDataLocal(clusterData: Types.ClusterData[]): Types.ClusterDataLocal[] {
    return clusterData.map((data) => ({
      index: data.index,
      selectedCluster: data.selectedCluster ?? undefined,
      selectedOperation: data.selectedOperation ?? this.operationContent[0],
      value: data.value,
    }));
  }

  handleUpdateFilterItem(keepReference = false, enableApplyButton = false) {
    this.filterService.updateCondition(this._filterItemLocal, keepReference, enableApplyButton);
    this.cdRef.detectChanges();
  }

  handleColumnSelect(value: Types.DropdownObjectType) {
    this._filterItemLocal.isCluster = value.value === 'RootCause' || value.value === 'CustomerPainPoint';
    this._filterItemLocal.selectedColumn = value;
    this.handleUpdateFilterItem();
  }

  handleClusterSelect(data: string, rootCauseDataLocal: Types.ClusterDataLocal) {
    rootCauseDataLocal.selectedCluster = { label: data, value: data };
    this.handleUpdateFilterItem(true, !!rootCauseDataLocal.value.length);
  }

  handleDeletePress() {
    this.filterService.removeCondition(this.filterItem.index);
  }

  handleOperationSelect(value: Types.DropdownObjectType, rootCauseDataLocal: Types.ClusterDataLocal) {
    rootCauseDataLocal.selectedOperation = value;
    this.handleUpdateFilterItem(true, !!rootCauseDataLocal.value.length);
  }

  handleInputChange(input: string, rootCauseDataLocal: Types.ClusterDataLocal) {
    rootCauseDataLocal.value = input;
    this.handleUpdateFilterItem(true, !!input.length);
  }

  handleAddButtonClick(event: MouseEvent, item: Types.ClusterDataLocal) {
    event.stopPropagation();
    this.filterService.addCluster(item.index, this._filterItemLocal.index);
  }

  handleCloseButtonClick(event: MouseEvent, item: Types.ClusterDataLocal) {
    event.stopPropagation();
    if (this._filterItemLocal.clusterData && this._filterItemLocal.clusterData.length > 1) {
      this.filterService.removeClusterItem(item.index, this._filterItemLocal.index);
    }
  }
}
