import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatTimestamp', standalone: true })
export class FormatTimestampPipe implements PipeTransform {

  transform(value: any): string {
    const seconds = this.parseSeconds(value);
    if (isNaN(seconds)) {
      return '-';
    }
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${this.padZero(minutes)}m:${this.padZero(remainingSeconds)}s`;
  }

  private parseSeconds(value: any): number {
    if (typeof value === 'string') {
      value = value.trim();
    }
    const num = typeof value === 'string' ? parseInt(value, 10) : value;
    return isNaN(num) ? NaN : num;
  }

  private padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}
