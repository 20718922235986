import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LabelService } from '../../../services/label.service';
@Component({
  selector: 'app-chat-box',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chat-box.component.html',
  styleUrl: './chat-box.component.scss',
})
export class ChatBoxComponent {
  constructor(public labelService: LabelService) { }
  data_navigator: any = {};
  prompts = [
    {
      title: 'Prompt Suggestion',
      text: 'Show me the percent of my calls that are general inquiries',
    },
    {
      title: 'Prompt Suggestion',
      text: 'Schedule Change has gone up in frequency',
    },
    {
      title: 'Prompt Suggestion',
      text: 'Let me see a graph of the relationship between topics and reasons',
    },
  ];

  tabName: any = ['Data Navigator', 'Chat', 'Glossary'];
  ngOnInit() {
    this.labelService.getLabelData().subscribe((data) => {
      this.data_navigator = data?.data_navigator ?? {};
    });
  }
}