import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sentimentText', standalone: true })
export class SentimentTextPipe implements PipeTransform {
  transform(value: string): string {
    const sentiment = value?.trim().toLowerCase();
    switch (sentiment) {
      case 'positive':
        return 'Positive';
      case 'negative':
        return 'Negative';
      case 'neutral':
        return 'Neutral';
      default:
        return '-';
    }
  }
}
