<div class="field-container">
  <app-button [buttonText]="buttonTitle" iconUrl="assets/icons/outlined.svg" (onButtonClick)="toggleDropdown()"
    [clicked]="_dropdownOpened()"></app-button>

  <div class="dropdown-content-container" *ngIf="_dropdownOpened()" appOutSideClick (outSideClick)="onOutsideClick()">
    <div class="option-search-container">
      <input type="text" [(ngModel)]="searchQuery" placeholder="Search" (click)="stopPropagation($event)"
        class="custom-input">
      <span class="close-icon" *ngIf="searchQuery" (click)="clearSearch(); $event.stopPropagation()"><img
          src="assets/icons/close.svg"></span>
    </div>

    <div class="columns-list">
      <div class="selected-list">
        <div class="list-header" *ngIf="!searchQuery.length">Selected Columns</div>
        <div class="column-item" *ngFor="let item of getColumnList(true)">
          <img class="clicked-checkbox" src="assets/icons/checked_box.svg"
            (click)="checkBoxClicked($event, item, true)">
          <div class="column-name">{{item.name}}</div>
          <div class="toggle">
          </div>
        </div>
      </div>
      <div class="not-selected-list">
        <div class="list-header other" *ngIf="!searchQuery.length">Other Columns</div>
        <div class="column-item" [ngClass]="{'disabled': getColumnCount()>=manageColumnCount }"
          *ngFor="let item of getColumnList()">
          <div class="empty-checkbox" (click)="checkBoxClicked($event, item)"></div>
          <div class="column-name">{{item.name}}</div>
          <div class="toggle">
          </div>
        </div>
      </div>
    </div>

    <div class="button-group">
      <div class="column-count">
        <div class="available-count">
          {{ getColumnCount() }}
        </div>/{{manageColumnCount}}
      </div>
      <div class="restore" (click)="handleRestore($event)">Restore Defaults</div>
    </div>
  </div>


</div>