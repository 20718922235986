import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { LabelService } from '../services/label.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  login_data: any = {};

  constructor(
    private authService: AuthService,
    public labelService: LabelService
  ) { }

  ngOnInit() {
    this.labelService.getLabelData().subscribe((data) => {
      this.login_data = data?.login ?? {};
    });
  }
  login() {
    this.authService.login();
  }
}
