<div class="container-class">
    <div class="heading-class">
        <span>{{data_navigator.data_navigator_name}}</span>
    </div>
    <div class="details-class">
        <div class="box-container">
            <div class="notification-title">
                <img src="../../../../assets/icons/loading-icon.svg" alt="loading">
                <span>{{data_navigator.coming_soon}}</span>
            </div>
            <div class="message">
                <span> {{data_navigator.stay_tuned}}</span>
            </div>
        </div>
    </div>
</div>