import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() buttonText: string = '';
  @Input() iconUrl: string = '';
  @Output() onButtonClick = new EventEmitter<void>();
  @Input() Color?: string = '';
  @Input() backgroundColor?: string = '';
  @Input() disabled?: boolean = false;
  @Input() clicked?: boolean = false;

  handleButtonClick = (event: MouseEvent) => {
    if (this.onButtonClick) {
      this.onButtonClick.emit();
    }
    event?.stopPropagation();
  };
}
