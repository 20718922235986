<div class="filter-section">
  <app-button [buttonText]="buttonTitle" iconUrl="assets/icons/filter_list.svg" (onButtonClick)="toggleDropdown()"
    [clicked]="_dropdownOpened()"></app-button>

  <div class="dropdown-content-container" [ngClass]="{ center: !filterData.length }" *ngIf="_dropdownOpened()"
    appOutSideClick (outSideClick)="onOutsideClick()">
    <div class="table-filter-dropdown">
      <table-filter></table-filter>
      <filter-save-dropdown (clearFilters)="handleClearFilters()"
        (onSaveFilters)="getFilterList()"></filter-save-dropdown>
    </div>
    <hr class="full-width-hr">
    <div class="container-header" [ngClass]="{ 'scrollable': filterData.length > 5 }">
      <div class="filter-list" *ngIf="filterData.length; else emptyLabel">

        <div class="show_title">
          <span class="show-header-title">{{dropdown.show_records}}</span>
        </div>
        <div cdkDropList [cdkDropListData]="filterData" (cdkDropListDropped)="drop($event)" class="item-wrapper">
          <div *ngFor="let item of filterData; index as i" cdkDrag
            [ngClass]="{ 'cluster-space': i > 0 && filterData[i - 1].clusterData}">
            <filter-item *ngIf="!item.isCluster; else clusterSection" [filterItem]="item"
              [filterItems]="filterData"></filter-item>
            <ng-template #clusterSection>
              <filter-cluster [filterItem]="item"></filter-cluster>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="add-condition-container">
        <!--add condition button--->
        <button class="custom-add-button" (click)="handleAddCondition()">
          <div class="button-content-add">
            <img src="assets/icons/add.svg" alt="Add" class="button-icon" />
            <span class="button-text">{{ dropdown.add_condition }}</span>
          </div>
        </button>
      </div>

      <ng-template #emptyLabel>
        <div class="header-title">{{ dropdown.no_filter_conditions_applied }}</div>
      </ng-template>
    </div>


    <div class="button-container" [ngClass]="{ center: !filterData.length }">
      <div class="add-button-wrapper">
        <ng-template #removeFilter>
          <app-button [buttonText]="dropdown.clear_filters" (onButtonClick)="handleClearFilters()"
            [backgroundColor]="'#EBEFF5'" [Color]="'#475258'">
          </app-button>
        </ng-template>
      </div>

      <div class="filter-button-section" *ngIf="filterData.length">
        <div class="add-button-wrapper">
          <app-button [buttonText]="dropdown.apply_filters" (onButtonClick)="handleApplyFilters()"
            [backgroundColor]="applyButtonActive ? '#534AD8' : '#BFCCD9'" [Color]="'#FFFFFF'">
          </app-button>
        </div>
      </div>
    </div>
  </div>
</div>