import { Component, Input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedDataService } from '../../services/shared-data.service';
import { LabelService } from '../../services/label.service';
import { Dropdown, LabelTypes } from '../../shared/label.types';
import { OutsideClickDirective } from '../../directives/outside-click.directive';
import { ButtonComponent } from '../button/button.component';
import * as Types from '../../shared/types';
import { CommonService } from '../../services/common.service';
import { ManageColumnCount } from '../../shared/constant';
import { formatToUpdateUserColumnPreference, transformColumnPreferences } from '../../shared/appUtil';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'manage-columns',
  standalone: true,
  imports: [CommonModule, FormsModule, OutsideClickDirective, ButtonComponent],
  templateUrl: './manage-columns.component.html',
  styleUrl: './manage-columns.component.scss',
})
export class ManageColumnsComponent {
  constructor(
    private sharedDataService: SharedDataService,
    private labelService: LabelService,
    private commonService: CommonService,
    public notificationService: NotificationService
  ) {}

  @Input() buttonTitle: string = '';
  protected _dropdownOpened = signal<boolean>(false);
  protected _listChanged = signal<boolean>(false);
  filterGroup: Types.FilterGroup = {} as Types.FilterGroup;
  searchQuery: string = '';
  dropdown: Dropdown = {} as Dropdown;
  manageColumnCount = ManageColumnCount;

  ngOnInit() {
    this.labelService.getLabelData().subscribe((data: LabelTypes) => {
      this.dropdown = data?.dropdown ?? {};
    });
    this.filterGroup = this.sharedDataService.getColumnsFilterGroup();

    this.sharedDataService.getToggleValues().subscribe((values) => {
      this._dropdownOpened.set(values.hideFields);
      if (this._listChanged()) {
        this._apiCall();
      }
    });
  }

  private _apiCall() {
    this.commonService
      .updateUserColumnPreference({ preferences: formatToUpdateUserColumnPreference(this.filterGroup.filters.options) })
      .subscribe((response) => {
        this.notificationService.success('Column Preference', 'Column Preference saved');
      });

    if (this._listChanged()) {
      this._listChanged.set(false);
    }
  }

  toggleDropdown = () => {
    this.sharedDataService.toggleHide(!this._dropdownOpened());
  };

  stopPropagation = (event: MouseEvent) => {
    event?.stopPropagation();
  };

  checkBoxClicked = (event: MouseEvent, item: Types.FilterOption, selected = false) => {
    event?.stopPropagation();
    if (selected) {
      item.isSelected = false;
      item.tempIsSelected = false;
    } else {
      item.isSelected = true;
      item.tempIsSelected = true;
    }
    this._listChanged.set(true);
    this.sharedDataService.handlePopulateFilteredData();
  };

  getColumnList = (selected = false): Array<Types.FilterOption> => {
    if (this.searchQuery.length) {
      return this.filterGroup.filters.options.filter(
        (item) => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()) && item.tempIsSelected === selected
      );
    }

    if (this.filterGroup.filters) {
      return this.filterGroup.filters.options.filter((i) => i.tempIsSelected === selected);
    }

    return [];
  };

  getColumnCount = () => {
    let columnCount = this.filterGroup.filters.options.filter((item) => item.tempIsSelected).length;
    return columnCount;
  };

  handleRestore = (event: MouseEvent) => {
    this.commonService.resetUserColumnPreference({}).subscribe((preferenceArray) => {
      this.sharedDataService.setColumnNames(transformColumnPreferences(preferenceArray));
      this.sharedDataService.handlePopulateFilteredData();
      this.notificationService.success('Column Preference', 'Column Preference restored');
    });
  };

  onOutsideClick = () => {
    this.sharedDataService.toggleHide(false);
  };

  clearSearch() {
    this.searchQuery = '';
  }
}
