import { Component } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavbarComponent } from '../navbar/navbar.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RightSidebarComponent } from '../dashboard/right-sidebar/right-sidebar.component';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-full-layout',
  standalone: true,
  imports: [
    MatSidenavModule,
    HeaderComponent,
    NavbarComponent,
    DashboardComponent,
    CommonModule,
    HttpClientModule,
    RightSidebarComponent,
  ],
  templateUrl: './full-layout.component.html',
  styleUrl: './full-layout.component.scss',
})
export class FullLayoutComponent {
  collapsed: boolean = true;

  onCollapsedChanged(collapsed: boolean) {
    this.collapsed = collapsed;
  }
}
