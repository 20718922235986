<div class="main-container">
  <div class="compressed-section">
    <div class="tab-section">
      <div class="chat-box-class" (click)="changeComponentSelector($event, 'chatbox')" matTooltip='{{glossary.chat_box}}'>
        <img *ngIf="componentSelector !== 'chatbox'" src="assets/icons/grey_insights.svg" alt="chatbox">
        <img *ngIf="componentSelector === 'chatbox'" src="assets/icons/white_insights.svg"
          [style.background-color]="componentSelector === 'chatbox' ? '#534AD8' : 'transparent'" alt="chatbox">
      </div>
      <div class="glossary-class" (click)="changeComponentSelector($event, 'glossary')" matTooltip='{{glossary.glossary}}'>
        <img *ngIf="componentSelector !== 'glossary'" src="assets/icons/grey_gloassary.svg" alt="glossary">
        <img *ngIf="componentSelector === 'glossary'" src="assets/icons/white_glossary.svg"
          [style.background-color]="componentSelector === 'glossary' ? '#534AD8' : 'transparent'" alt="glossary">
      </div>
    </div>
  </div>
  <div class="expanded-section" [ngClass]="{'hidden': !isExpandBoxVisible}">
    <div class="component-caller">
      <app-chat-box [ngClass]="{'hidden': componentSelector !== 'chatbox' }"></app-chat-box>
      <app-glossary [ngClass]="{'hidden': componentSelector !== 'glossary' }"></app-glossary>
    </div>
  </div>
</div>