<div class="card">
  <div class="content-item-card  overview-details-container" *ngFor="let topic of topics">
    <label>{{ topic.label }}</label>
    <div class="data" *ngIf="!loaderFlag" [ngClass]="topic.key === 'Overall Sentiment' ? getSentimentClass() : ''">
      <ng-container *ngIf="topic.key === 'Issue Resolution Rate'; else regularFormat">
        {{ getTopic(topic.key) }}
      </ng-container>
      <ng-template #regularFormat>
        {{ getTopic(topic.key) }}
      </ng-template>
    </div>
    <app-skeleton-loader *ngIf="loaderFlag" [width]="'80px'" [height]="'20px'"></app-skeleton-loader>
  </div>
</div>