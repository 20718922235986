import { Component, computed, EventEmitter, Input, Output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { FilterService } from '../../../services/filter.service';
import * as Types from '../../../shared/types';
import { OutsideClickDirective } from '../../../directives/outside-click.directive';
import { convertToFilterCondition } from '../../../shared/appUtil';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'table-filter',
  standalone: true,
  imports: [CommonModule, FormsModule, OutsideClickDirective,MatTooltip],
  templateUrl: './table-filter.component.html',
  styleUrl: './table-filter.component.scss',
})
export class TableFilterComponent {
  constructor(
    public commonService: CommonService,
    public filterService: FilterService
  ) { }

  protected _savedFilterList = computed<Array<Types.FilterListResponse>>(() =>
    this.filterService.savedFilterListState()
  );
  selectedClusterText: string = 'Select Filter';
  isOptionSelected = false;
  dropdownOpened: boolean = false;
  searchQuery: string = '';
  listHeader = true;

  ngOnInit() {
    this.filterService.getSavedFilterLabel().subscribe((label) => {
      // refresh the list when the saved label is updated
      if (label != this.selectedClusterText && label.length) {
        this.selectedClusterText = label;
      } else if (label === '') {
        this.selectedClusterText = 'Select Filter';
        this.isOptionSelected = false;
      }
    });
  }

  handleButtonClick(event: Event): void {
    this.dropdownOpened = !this.dropdownOpened;
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  clearSearch(): void {
    this.searchQuery = '';
  }

  onOutsideClick(): void {
    this.dropdownOpened = false;
  }

  handleOptionSelect(event: Event, item: Types.FilterListResponse): void {
    this.selectedClusterText = item.tag;
    this.filterService.setSavedFilterLabel(item.tag);
    this.isOptionSelected = true;

    const data = [...convertToFilterCondition(item.filter.filter)];
    this.filterService.updateFilterData(data);

    this.dropdownOpened = false;
    event.stopPropagation();
  }

  getFilteredTopics() {
    if (this.searchQuery) {
      return this._savedFilterList().filter((item) =>
        item.tag.toLocaleLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }

    return this._savedFilterList();
  }

  get truncatedClusterText(): string {
    const maxLength = 15;
    return this.selectedClusterText.length > maxLength
      ? this.selectedClusterText.substring(0, maxLength) + '...'
      : this.selectedClusterText;
  }
}