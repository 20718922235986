import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { AlertTypes, DataType } from '../../shared/types';

@Component({
  selector: 'snack-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {
  icons = {
    [AlertTypes.Error]: 'content',
    [AlertTypes.Info]: 'alert_info',
    [AlertTypes.Success]: 'status-icon',
    [AlertTypes.Processing]: 'alert_processing',
  };

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: DataType,
    private snackRef: MatSnackBarRef<SnackBarComponent>
  ) { }

  close() {
    this.snackRef.dismiss();
  }
}
