<div class="more-wrapper">
  <img src="assets/icons/more_vert.svg" alt="More" (click)="toggleMoreDropdown($event)">
</div>

<!--export dropdown-->
<div class="export-container">
  <div class="dropdown-content-container-export" *ngIf="_dropdownOpened()" appOutSideClick
    (outSideClick)="onOutsideClick()">
    <ul class="dropdown-menu">
      <li class="dropdown-item" (click)="onDownload()">
        <img src="assets/icons/file_download.svg" alt="Export Data">
        {{dashboard.export_data}}
      </li>
      <!-- Not required for AC V4.2 -->
      <!-- <li class="dropdown-item">
          <img src="assets/icons/view_agenda.svg" alt="Generate Report">
          {{dashboard.generate_report}}
      </li> -->
    </ul>
  </div>
</div>