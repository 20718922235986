import { Component, EventEmitter, Inject, Output, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopupData } from '../../shared/types';
import { OutsideClickDirective } from '../../directives/outside-click.directive';

@Component({
  selector: 'confirm-popup',
  standalone: true,
  imports: [OutsideClickDirective],
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
})
export class ConfirmPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopupData
  ) {}

  popupContent = signal({
    title: 'Title',
    message: 'message?',
    iconUrl: 'assets/icons/warning_amber.svg',
    confirmBtnText: 'Confirm',
    cancelBtnText: 'Cancel',
  });

  ngOnInit() {
    if (this.data) {
      this.popupContent.update((previous) => ({ ...previous, ...this.data }));
    }
  }

  onCancel(event: MouseEvent): void {
    this.dialogRef.close({ event: 'close', data: { confirm: false } });
    event.stopPropagation();
  }

  onConfirm(event: MouseEvent) {
    this.dialogRef.close({ event: 'delete', data: { confirm: true } });
    event.stopPropagation();
  }

  onOutsideClick = (event: MouseEvent) => {
    event.stopPropagation();
  };
}
