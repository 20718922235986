<div class="dashboard-container">
  <div class="left-main-content">
    <conversation-overview></conversation-overview>

    <div class="tab-container" [ngClass]="{'extra-padding': !tableViewFlag}">
      <div class="container">
        <!-- Tab Navigation -->
        <div class="tabs">
          <div class="tab" [class.active]="activeTab === 'visualization'" (click)="showTab('visualization')"
            [ngClass]="{'active': !tableViewFlag}" (click)="showChartView()">{{dashboard.chart_view}}</div>
          <div class="tab" [class.active]="activeTab === 'tabular'" (click)="showTab('tabular')"
            [ngClass]="{'active': tableViewFlag}" (click)="showDataView()">{{dashboard.data_view}}</div>
        </div>
      </div>

      <div class="right-content">
        <filter-section [buttonTitle]="dashboard.filter"> </filter-section>
        <manage-columns *ngIf="tableViewFlag" [buttonTitle]="dashboard.manage_column"></manage-columns>
        <!-- Not required for AC V4.2 -->
        <!-- <app-button [buttonText]="dashboard.group" iconUrl="assets/icons/list_alt.svg" [disabled]="true"
          (onButtonClick)="toggleDropdown()"></app-button> -->
        <!-- <app-button [buttonText]="dashboard.sort" iconUrl="assets/icons/import_export.svg" [disabled]="true"
          (onButtonClick)="toggleDropdown()"></app-button> -->
        <ng-template #container></ng-template>
        <!-- <app-button iconUrl="assets/icons/format_line_spacing.svg" (onButtonClick)="toggleDropdown()"
          [disabled]="true"></app-button> -->
        <!-- <app-button iconUrl="assets/icons/search_new.svg" (onButtonClick)="toggleDropdown()"
          [disabled]="true"></app-button> -->
        <app-search-dropdown *ngIf="tableViewFlag"></app-search-dropdown>
        <!-- <div class="more-wrapper">
          <img src="assets/icons/more_vert.svg" alt="More" (click)="toggleMoreDropdown()">
        </div> -->
        <more-drop-down *ngIf="tableViewFlag" (handleOnDownload)="onDownload()"></more-drop-down>
      </div>
    </div>

    <!-- Render data view mode -->
    <div class="raw-table-view" *ngIf="tableViewFlag && !columnLoading">
      <app-tabular-view></app-tabular-view>
    </div>

    <div class="explorer-view" *ngIf="!tableViewFlag">
      <app-data-explorer [chatBoxVisibilityFlag]="isChatBoxVisible"></app-data-explorer>
    </div>

  </div>
  <div class="chatbox-main-container">
    <app-right-sidebar (valueChange)="onValueChange($event)"></app-right-sidebar>
  </div>

</div>