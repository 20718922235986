import { Component, Input } from '@angular/core';
import { LabelService } from '../../services/label.service';
import { Labels, LabelTypes } from '../../shared/label.types';
import { CommonModule } from '@angular/common';
import { SkeletonLoaderComponent } from '../dashboard/skeleton-loader/skeleton-loader.component';
import * as Types from '../../shared/types';
import { CommonService } from '../../services/common.service';
import { SharedDataService } from '../../services/shared-data.service';

@Component({
  selector: 'conversation-overview',
  standalone: true,
  imports: [CommonModule, SkeletonLoaderComponent],
  templateUrl: './conversation-overview.component.html',
  styleUrl: './conversation-overview.component.scss',
})
export class ConversationOverviewComponent {
  topics: Array<{ label: string; key: string }> = [];
  labels: Labels = {} as Labels;
  conversationOverview: Types.Overview = {} as Types.Overview;
  loaderFlag: boolean = false;

  constructor(
    public commonService: CommonService,
    public labelService: LabelService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit(): void {
    this.commonService.getLoaderFlag().subscribe((flag) => {
      this.loaderFlag = flag;
    });
  
    this.labelService.getLabelData().subscribe((data: LabelTypes) => {
      this.labels = data?.labels ?? {};
      this.initializeTopics();
    });
  
    this.sharedDataService.getConversationOverview().subscribe((data) => {
      this.conversationOverview = data;
      this.loaderFlag = false;
    });
    this.loaderFlag = true;
  }
  

  initializeTopics(): void {
    this.topics = [
      { label: this.labels.total_conversations, key: 'Total Conversations' },
      { label: this.labels.primary_topics, key: 'Primary Topics' },
      // { label: this.labels.reason, key: 'Call Reason' },
      // { label: this.labels.root_cause, key: 'Root Causes' },
      { label: this.labels.average_duration, key: 'Average Duration' },
      { label: this.labels.issue_resolution_rate, key: 'Issue Resolution Rate' },
      { label: this.labels.overall_sentiment, key: 'Overall Sentiment' },
    ];
  }

  getTopic = (topicKey: string) => {
    const value = this.conversationOverview[topicKey];
    switch (topicKey) {
      case 'Issue Resolution Rate':
        return value ? `${(value as number).toFixed(2)}%` : '0.00%';

      case 'overall_sentiment':
        return value || 'N/A';

      default:
        return value || 'N/A';
    }
  };

  getSentimentClass(sentiment: string = this.conversationOverview?.['Overall Sentiment']): string {
    if (sentiment === 'Positive') {
      return 'positive-sentiment';
    } else if (sentiment === 'Negative') {
      return 'negative-sentiment';
    } else if (sentiment === 'Neutral') {
      return 'neutral-sentiment';
    } else {
      return 'default-sentiment';
    }
  }
}
