<div class="item-container">
  <div class="operation">
    <div class="condition-label" *ngIf="!_filterItemLocal.operator; else operator">
      {{filter.where}}
    </div>
    <ng-template #operator>
      <div class="operator-wrapper" *ngIf="checkOperator(); else operatorLabel">
        <custom-select [selectedValue]="_filterItemLocal.operator" [contentList]="operatorContent"
          (onItemSelect)="handleOperatorSelect($event)" [showSearch]="false" listHeader="Operator">
        </custom-select>
      </div>
      <ng-template #operatorLabel>
        <div class="condition-label">
          {{getOperatorText()}}
        </div>
      </ng-template>
    </ng-template>
  </div>
  <div class="right-content">
    <div class="dropdown-wrapper">
      <custom-select [selectedValue]="_filterItemLocal.selectedColumn" [contentList]="columnNames"
        (onItemSelect)="handleColumnSelect($event)">
      </custom-select>
    </div>

    <!-- Conditionally show the second dropdown -->
    <div class="dropdown-wrapper">
      <custom-select [selectedValue]="_filterItemLocal.selectedOperation" [contentList]="operationContent"
        [showHiButton]="showUploadButton" (onItemSelect)="handleOperationSelect($event)"
        (hiButtonClicked)="handleHiButtonClick()"
        (onFileDataParsed)="handleFileData($event.fileName, $event.parsedData)">
      </custom-select>
    </div>

    <div class="dropdown-wrapper">
      <ng-container *ngIf="fileUploaded; else conditionalDisplay">
        <div class="file-display">
          <img src="assets/icons/check_circle.svg" alt="Yes" class="icon yes-icon">
          <span class="file-name" *ngIf="fileName">{{ fileName }}</span>
          <img src="assets/icons/close.svg" alt="Close" class="icon close-icon" (click)="clearFileName()">
        </div>
      </ng-container>

      <!-- Conditional display for custom input or topics field -->
      <ng-template #conditionalDisplay>
        <ng-container *ngIf="isTopicsSelected(); else otherField">
          <app-topics-field [inputText]="_filterItemLocal.value"
            (onInputChange)="handleInputTopicsChange($event)"></app-topics-field>
        </ng-container>

        <ng-template #otherField>
          <ng-container *ngIf="isArraySelected(); else textField">
            <custom-select [selectedValue]="_selectedArrayValue()" [contentList]="_arrayData()"
              (onItemSelect)="handleInputArrayChange($event)">
            </custom-select>
          </ng-container>
        </ng-template>

        <!-- Show topics field if topics are selected -->
        <ng-template #textField>
          <custom-input [inputText]="_filterItemLocal.value" (onInputChange)="handleInputChange($event)"
            [singleDatePicker]="singleDatePicker" [customInputType]="selectedInputType"
            (onDateSelect)="handleInputChange($event)">
          </custom-input>
        </ng-template>
      </ng-template>
    </div>


    <!-- Delete button -->
    <div class="delete-icon-wrapper">
      <app-button [buttonText]="''" [backgroundColor]="'#FFE7E3'" iconUrl="assets/icons/delete_outline.svg"
        (onButtonClick)="handleDeletePress()">
      </app-button>
    </div>

    <!-- Move button -->
    <div class="move-icon-wrapper" cdkDragHandle>
      <app-button [buttonText]="''" iconUrl="assets/icons/apps-round.svg"></app-button>
    </div>
  </div>
</div>