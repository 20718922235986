import { CommonModule } from '@angular/common';
import { Component, computed, EventEmitter, Output } from '@angular/core';

import { CustomInputComponent } from '../../custom-input/custom-input.component';
import { CommonService } from '../../../services/common.service';
import { OutsideClickDirective } from '../../../directives/outside-click.directive';
import { FilterService } from '../../../services/filter.service';
import { FilterRequest } from '../../../shared/types';
import { transformTestToFilters } from '../../../shared/appUtil';
import { NotificationService } from '../../../services/notification.service';
import { LabelService } from '../../../services/label.service';
import { Filters_Save } from '../../../shared/label.types';

@Component({
  selector: 'filter-save-dropdown',
  standalone: true,
  imports: [CommonModule, CustomInputComponent, OutsideClickDirective],
  templateUrl: './filter-save-dropdown.component.html',
  styleUrl: './filter-save-dropdown.component.scss',
})
export class FilterSaveDropdownComponent {
  constructor(
    private commonService: CommonService,
    private filterService: FilterService,
    public notificationService: NotificationService,
    private labelService: LabelService
  ) { }

  protected _savedButtonEnabled = computed<boolean>(() => this.filterService.saveButtonEnabled());
  dropdownOpened: boolean = false;
  labelPopupOpened: boolean = false;
  saveDisabled: boolean = true;
  labelText: string = '';
  savedLabelText: string = '';
  filter_save: any = {};

  @Output() clearFilters = new EventEmitter<void>();
  @Output() onSaveFilters = new EventEmitter<void>();

  ngOnInit() {
    this.filterService.getSavedFilterLabel().subscribe((label) => {
      this.savedLabelText = label;
    });
    this.labelService.getJsonData().subscribe((data) => {
      this.filter_save = data?.filter_save ?? {};
    })
  }

  toggleMoreDropdown = () => {
    this.dropdownOpened = !this.dropdownOpened;
    if (this.labelPopupOpened) {
      this.labelPopupOpened = false;
    }
  };

  toggleLabelDropdown = () => {
    this.labelPopupOpened = !this.labelPopupOpened;
  };

  handleCancelPress = (event: MouseEvent) => {
    this.labelPopupOpened = false;
    event?.stopPropagation();
  };

  handleSavePress = (event: MouseEvent) => {
    const requestData = {
      tag: this.savedLabelText,
      filter: { filter: this.populateFilteredData() },
    };
    if (requestData.filter.filter.length) {
      this.commonService.updateFilters(requestData).subscribe({
        next: () => {
          this.filterService.updateSavedFilterList(
            {
              ...requestData,
              updated_at: '',
            },
            true
          );
          this.filterService.setSavedButtonEnabled(false);
        },
      });
      this.toggleMoreDropdown();
    } else {
      this.notificationService.error('Error saving filter', 'Filters cannot be empty');
    }

    event?.stopPropagation();
  };

  populateFilteredData(): FilterRequest[] {
    const filterDataArray = this.filterService.getFilterConditionDataValue();
    const filteredData: FilterRequest[] = [...transformTestToFilters(filterDataArray)];
    return filteredData;
  }

  handleSaveAsNewPress = (event: MouseEvent) => {
    const requestData = {
      tag: this.labelText,
      filter: { filter: this.populateFilteredData() },
    };

    if (requestData.filter.filter.length) {
      this.commonService.saveFilters(requestData).subscribe({
        next: () => {
          this.filterService.setSavedFilterLabel(this.labelText);
          this.filterService.updateSavedFilterList({
            ...requestData,
            updated_at: '',
          });
          this.filterService.setSavedButtonEnabled(false);
        },
      });

      this.toggleMoreDropdown();
    } else {
      this.notificationService.error('Error saving filter', 'Filters cannot be empty');
    }

    event?.stopPropagation();
  };

  handleClearFilters = (event: MouseEvent) => {
    if (this.clearFilters) {
      this.clearFilters.emit();
    }
    this.toggleMoreDropdown();
    event?.stopPropagation();
  };

  stopPropagation = (event: MouseEvent) => {
    event?.stopPropagation();
  };

  onOutsideClick = () => {
    this.toggleMoreDropdown();
  };

  handleInputChange = (input: string) => {
    this.labelText = input;
  };
}
