import { Component, Input, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomSelectComponent } from '../../custom-select/custom-select.component';
import * as Types from '../../../shared/types';
import { transformTableHeaders } from '../../../shared/appUtil';
import { ButtonComponent } from '../../button/button.component';
import { FilterService } from '../../../services/filter.service';
import { ClusterColumns, ClusterNames, FILTER_OPERATIONS } from '../../../shared/constant';
import { SharedDataService } from '../../../services/shared-data.service';
import { LabelService } from '../../../services/label.service';
import { Filters, LabelTypes } from '../../../shared/label.types';
import { ClusterItemComponent } from './cluster-item/cluster-item.component';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'filter-cluster',
  standalone: true,
  imports: [CustomSelectComponent, ButtonComponent, CommonModule, ClusterItemComponent],
  templateUrl: './filter-cluster.component.html',
  styleUrls: ['./filter-cluster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterClusterComponent implements OnInit {
  @Input() filterItem: Types.FilterCondition = {} as Types.FilterCondition;
  @Input() filterItems: Types.FilterCondition[] = [];

  _filterItemLocal: Types.FilterItemLocal = {} as Types.FilterItemLocal;
  columnNames: Array<Types.DropdownObjectType> = [];
  operationContent: Array<Types.DropdownObjectType> = FILTER_OPERATIONS.cluster;
  selectedInputType: Types.CustomInputType = Types.CustomInputType.string;

  filters: Filters = {} as Filters;
  @Input() openAbove: boolean = false;
  filterData: Array<Types.FilterCondition> = [];

  constructor(
    private filterService: FilterService,
    private sharedDataService: SharedDataService,
    private labelService: LabelService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.labelService.getLabelData().subscribe((data: LabelTypes) => {
      this.filters = data?.filter ?? {};
      this.cdRef.markForCheck();
    });

    this.sharedDataService.getColumnNames().subscribe((names) => {
      this.columnNames = transformTableHeaders(names);
      this.cdRef.markForCheck();
    });

    this.initializeFilterItemLocal();
  }

  initializeFilterItemLocal() {
    this._filterItemLocal = {
      index: this.filterItem.index,
      operator: this.filterItem.operator ? { value: this.filterItem.operator, label: this.filterItem.operator } : null,
      selectedColumn: this.filterItem.selectedColumn,
      selectedOperation: this.filterItem.selectedOperation,
      value: this.filterItem.value,
      isCluster: this.filterItem.isCluster,
      clusterName: this.filterItem.clusterName,
      clusterData: this.convertToRootCauseDataLocal(this.filterItem.clusterData),
    };
  }

  convertToRootCauseDataLocal(clusterData: Types.ClusterData[]): Types.ClusterDataLocal[] {
    return clusterData.map((data) => ({
      index: data.index,
      selectedCluster: data.selectedCluster ?? undefined,
      selectedOperation: data.selectedOperation ?? this.operationContent[0],
      value: data.value,
    }));
  }

  handleUpdateFilterItem(keepReference = false, enableApplyButton = false) {
    this.filterService.updateCondition(this._filterItemLocal, keepReference, enableApplyButton);
    this.cdRef.detectChanges();
  }

  getClusterName = (selectedColumn: string) => {
    if (selectedColumn && selectedColumn in ClusterNames) {
      // Safe access: `selectedColumn.value` is a valid key of ClusterNames
      const clusterName = ClusterNames[selectedColumn as keyof typeof ClusterNames];
      return clusterName;
    }
    return undefined; // Handle invalid or missing selectedColumn
  };

  handleColumnSelect(value: Types.DropdownObjectType) {
    this._filterItemLocal.isCluster = Object.keys(ClusterColumns).includes(value.value);
    this._filterItemLocal.clusterName = this.getClusterName(value.value);

    this._filterItemLocal.selectedColumn = value;
    this._filterItemLocal.clusterData = this.convertToRootCauseDataLocal([
      {
        index: 1,
        selectedCluster: undefined,
        selectedOperation: undefined,
        value: '',
      },
    ]);
    this.handleUpdateFilterItem();
  }

  handleDeletePress() {
    this.filterService.removeCondition(this.filterItem.index);
  }
}
