import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CustomInputType } from '../../shared/types';
import { FilterDaterangePickerComponent } from '../filter-date-range-picker/filter-date-range-picker.component';

@Component({
  selector: 'custom-input',
  standalone: true,
  imports: [CommonModule, FormsModule, FilterDaterangePickerComponent],
  templateUrl: './custom-input.component.html',
  styleUrl: './custom-input.component.scss',
})
export class CustomInputComponent {
  @Input() inputText: string | string[] = '';
  @Input() customInputType: CustomInputType = CustomInputType.string;
  @Input() placeholderText: string = 'Enter Value';
  @Input() singleDatePicker: boolean = true;
  @Input() maxLength?: number; 
  @Output() onInputChange = new EventEmitter<string>();
  @Output() onDateSelect = new EventEmitter<string | string[]>();


  handleInputChange = (input: string) => {
    if (this.customInputType === CustomInputType.number) {
      const numericInput = Number(input);

      if (numericInput < 0) {
        this.inputText = Math.abs(numericInput).toString();
        return;
      }
    }

    if (this.onInputChange) {
      this.onInputChange.emit(input);
    }
  };

  stopPropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };

  getInputType = () => {
    if (this.customInputType === CustomInputType.string) {
      return 'text';
    } else if (this.customInputType === CustomInputType.number) {
      return 'number';
    } else if (this.customInputType === CustomInputType.array) {
      return 'select';
    }
    return 'text';
  };

  handleDateSelect = (date: string | string[]) => {
    if (this.onDateSelect) {
      this.onDateSelect.emit(date);
    }
  };
}