<div [className]="'custom-snack-bar-container'" [ngClass]="{ compact: !data.message || !data.title }">

  <!-- Conditionally display icon based on status -->
  <img *ngIf="icons[data.status]" [src]="'assets/icons/' + icons[data.status] + '.svg'" alt="{{ data.status }} Icon"
    class="default-icon" />

  <div class="text-container">
    <div class="description" *ngIf="!data.message">{{ data.title }}</div>
    <div class="description" *ngIf="data.message">{{ data.message }}</div>
  </div>
</div>

<div class="close" (click)="close()">
  <img src="assets/icons/close.svg" alt="Close Icon" class="clear-icon" />
</div>