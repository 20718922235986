import { CommonModule } from '@angular/common';
import { Component, computed, Input, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import * as Types from '../../shared/types';
import { ToggleComponent } from './toggle/toggle.component';
import { OutsideClickDirective } from '../../directives/outside-click.directive';
import { ButtonComponent } from '../button/button.component';
import { SharedDataService } from '../../services/shared-data.service';
import { LabelService } from '../../services/label.service';
import { Dropdown, LabelTypes } from '../../shared/label.types';

@Component({
  selector: 'hide-fields',
  standalone: true,
  imports: [CommonModule, MatSlideToggleModule, FormsModule, ToggleComponent, OutsideClickDirective, ButtonComponent],
  templateUrl: './hide-fields.component.html',
  styleUrl: './hide-fields.component.scss',
})
export class HideFieldsComponent {
  constructor(
    private sharedDataService: SharedDataService,
    private labelService: LabelService
  ) { }

  @Input() buttonTitle: string = '';
  protected _dropdownOpened = signal<boolean>(false);

  filterGroup: Types.FilterGroup = {} as Types.FilterGroup;
  searchQuery: string = '';
  dropdown: Dropdown = {} as Dropdown;

  ngOnInit() {
    this.labelService.getLabelData().subscribe((data: LabelTypes) => {
      this.dropdown = data?.dropdown ?? {};
    });
    this.filterGroup = this.sharedDataService.getColumnsFilterGroup();

    this.sharedDataService.getToggleValues().subscribe((values) => {
      this._dropdownOpened.set(values.hideFields);
    });
  }

  toggleDropdown = () => {
    this.sharedDataService.toggleHide(!this._dropdownOpened());
  };

  stopPropagation = (event: MouseEvent) => {
    event?.stopPropagation();
  };

  onValueChange(currentValue: Event, item: Types.FilterOption) {
    let currentCheck = item.tempIsSelected || item.tempIsSelected;
    item.isSelected = !currentCheck;
    item.tempIsSelected = !currentCheck;
    this.sharedDataService.handlePopulateFilteredData();
  }

  getFilters = (): Array<Types.FilterOption> => {
    if (this.searchQuery.length) {
      return this.filterGroup.filters.options.filter((item) =>
        item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }

    if (this.filterGroup.filters) {
      return this.filterGroup.filters.options;
    }

    return [];
  };

  handleHideAll = () => {
    this.sharedDataService.handleHideAll();
  };

  handleShowAll = () => {
    this.sharedDataService.handleShowAll();
  };

  getColumnCount = (): string => {
    let columnCount = this.filterGroup.filters.options.length ?? 0;
    if (this.searchQuery.length) {
      columnCount = this.filterGroup.filters.options.filter((item) =>
        item.name.toLowerCase().includes(this.searchQuery)
      ).length;
    }

    if (columnCount > 1) {
      return `${columnCount} fields`;
    }
    return `${columnCount} field`;
  };

  onOutsideClick = () => {
    this.sharedDataService.toggleHide(false);
  };

  clearSearch() {
    this.searchQuery = '';
  }
}
