<div class="search-wrapper">
    <img src="assets/icons/search.svg" alt="More" (click)="toggleSearchDropdown($event)">
  </div>
  
  <!--search dropdown-->
  <div class="search-container-box">
    <div class="dropdown-content-container-search" *ngIf="_dropdownOpened()" appOutSideClick
      (outSideClick)="onOutsideClick()">
     <div class="search-container">
        <div class="search-box">
            <img src="assets/icons/search.svg" alt="search" class="search-icon">
        <input type="text" [(ngModel)]="searchQuery"  placeholder="Search" (click)="stopPropagation($event)"
        class="custom-input">
      <span class="close-icon" *ngIf="searchQuery" (click)="clearSearch(); $event.stopPropagation()"><img
          src="../../../assets/icons/close.svg"></span>
        </div>
     </div>
     <div class="search-count-contanier">
        <div class="search-count">{{ totalCount }} rows found</div>
        <div class="search-button">
            <app-button [buttonText]="dashboard.reset_search" (onButtonClick)="resetSearch()"
            [backgroundColor]="'#F5F7FA'" [Color]="'#1E2225'">
          </app-button>
            <app-button [buttonText]="dashboard.search" (onButtonClick)="handleSearch()"
            [backgroundColor]=" searchQuery ?'#534AD8':'#BFCCD9'  " [Color]="'#FFFFFF'">
          </app-button>
        </div>
     </div>
    </div>
  </div>