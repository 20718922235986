<div class="popup-overlay">
  <div class="popup-content" (click)="onOutsideClick($event)">
    <div class="popup-header">
      <img class="popup-icon" [src]="popupContent().iconUrl">
      <div class="popup-title">{{ popupContent().title }}</div>
    </div>
    <div class="popup-body">
      <div class="popup-body-text">{{ popupContent().message }}</div>
    </div>
    <div class="popup-footer">
      <button class="popup-button confirm" (click)="onConfirm($event)">{{ popupContent().confirmBtnText }}</button>
      <button class="popup-button cancel" (click)="onCancel($event)">{{ popupContent().cancelBtnText }}</button>
    </div>
  </div>
</div>