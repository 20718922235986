<div class="select-container">
  <div class="select-button" [matMenuTriggerFor]="dropdownMenu" (click)="handleButtonClick($event)"
    [matTooltip]="selectedValue?.label | tooltip">
    <div class="button-text">{{selectedValue?.label | truncate}}</div>
    <div class="button-icon">
      <img src="assets/icons/keyboard_arrow_down_new.svg">
    </div>
  </div>

  <mat-menu [class]="'select-list'" #dropdownMenu="matMenu" xPosition="before" yPosition="below"
    (closed)="onMatMenuClosed()">
    <div class="container-header-custom">
      <div class="header-title" *ngIf="listHeader">{{listHeader}}</div>
    </div>
    <div class="option-search-container">
      <input type="text" [(ngModel)]="searchQuery" placeholder="Search" (click)="stopPropagation($event)"
        class="custom-input">
      <span class="close-icon" *ngIf="searchQuery" (click)="clearSearch(); $event.stopPropagation()"><img
          src="../../../assets/icons/close.svg"></span>
    </div>

    <div class="columns-list">
      <div class="column-item" *ngFor="let item of getFilters()" (click)="handleItemSelect($event, item)">
        <div class="column-name">{{item.label}}</div>
      </div>
    </div>
    <!-- upload document-->
    <div class="custom-button-style" *ngIf="showHiButton" (click)="triggerFileInput()">
      <img class="file-upload-icon" src="assets/icons/file_upload.svg">
      {{ filters.upload_document }}
    </div>
    <input class="custom-file-input" type="file" #fileInput (change)="handleFileSelected($event)" style="display: none;"
      accept=".csv" />
  </mat-menu>

</div>