import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertTypes } from '../shared/types';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private _snackBar: MatSnackBar) { }
  processing(title = 'Processing', message = 'Processing', duration = 3000, config = null) {
    this.showNotification(title, message, AlertTypes.Processing, duration, config);
  }

  success(title = 'Success', message = 'success', duration = 3000, config = null) {
    this.showNotification(title, message, AlertTypes.Success, duration, config);
  }

  error(title = 'Error', message = 'Failed!', duration = 3000, config = null) {
    this.showNotification(title, message, AlertTypes.Error, duration, config);
  }

  private showNotification(title: string, message: string, type: AlertTypes, duration: number, config: null) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: duration,
      panelClass: ['custom-error-snackbar'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
      data: {
        title: title,
        message: message,
        status: type,
        config: config,
      },
    });
  }
}
