<div class="cluster-container">
  <div class="operation">
    <div class="condition-label">
      {{ filters.where }}
    </div>
  </div>
  <div class="right-content">
    <div class="top-row">
      <custom-select [selectedValue]="_filterItemLocal.selectedColumn" [contentList]="columnNames"
        (onItemSelect)="handleColumnSelect($event)">
      </custom-select>
      <div class="button-group">
        <!-- Delete button -->
        <div class="delete-icon-wrapper">
          <app-button [buttonText]="''" [backgroundColor]="'#FFE7E3'" iconUrl="assets/icons/delete_outline.svg"
            (onButtonClick)="handleDeletePress()">
          </app-button>
        </div>
        <!-- Move button -->
        <div class="move-icon-wrapper" cdkDragHandle>
          <app-button [buttonText]="''" iconUrl="assets/icons/apps-round.svg">
          </app-button>
        </div>
      </div>
    </div>

    <div class="cluster-item-wrapper">
      <div class="cluster-item" *ngFor="let item of _filterItemLocal?.clusterData; let last = last">
        <div class="select-wrapper">
          <app-data-cluster-field [selectedColumn]="_filterItemLocal.selectedColumn" [inputText]="item?.selectedCluster"
            (onInputChange)="handleClusterSelect($event, item)">
          </app-data-cluster-field>
        </div>

        <div class="select-wrapper">
          <custom-select [selectedValue]="item?.selectedOperation" [contentList]="operationContent"
            (onItemSelect)="handleOperationSelect($event, item)">
          </custom-select>
        </div>

        <div class="select-wrapper">
          <custom-input [inputText]="item.value" (onInputChange)="handleInputChange($event, item)"></custom-input>
        </div>

        <div class="close-icon-wrapper" [ngClass]="{ disable: _filterItemLocal.clusterData?.length === 1 }"
          (click)="handleCloseButtonClick($event, item)">
          <img src="assets/icons/close_filter.svg" />
        </div>

        <div *ngIf="last" class="add-icon-wrapper" (click)="handleAddButtonClick($event, item)">
          <img src="assets/icons/add.svg" />
        </div>
      </div>
    </div>
  </div>
</div>