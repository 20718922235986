import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { LabelTypes } from '../shared/label.types';
import labelData from '../../assets/data/labels-data.json';

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  private labelDataUrl = 'assets/data/labels-data.json';
  private _labelData$: BehaviorSubject<LabelTypes>;

  constructor(private http: HttpClient) {
    this._labelData$ = new BehaviorSubject(labelData as LabelTypes);
  }

  getLabelData() {
    return this._labelData$.asObservable();
  }

  getJsonData = (): Observable<LabelTypes> => {
    return this.http.get<LabelTypes>(this.labelDataUrl);
  };
}
