<div class="cluster-container">
  <div class="operation">
    <div class="condition-label">
      {{ filters.where }}
    </div>
  </div>
  <div class="right-content">
    <div class="top-row">
      <custom-select [selectedValue]="_filterItemLocal.selectedColumn" [contentList]="columnNames"
        (onItemSelect)="handleColumnSelect($event)" [openAbove]="openAbove" >
      </custom-select>
      <div class="button-group">
        <!-- Delete button -->
        <div class="delete-icon-wrapper">
          <app-button [buttonText]="''" [backgroundColor]="'#FFE7E3'" iconUrl="assets/icons/delete_outline.svg"
            (onButtonClick)="handleDeletePress()">
          </app-button>
        </div>
        <!-- Move button -->
        <div class="move-icon-wrapper" cdkDragHandle>
          <app-button [buttonText]="''" iconUrl="assets/icons/apps-round.svg">
          </app-button>
        </div>
      </div>
    </div>

    <div class="cluster-item-wrapper">
      <div class="cluster-item" *ngFor="let item of _filterItemLocal?.clusterData; let last = last">
        <cluster-item [_filterItemLocal]="_filterItemLocal" [item]="item" [last]="last"></cluster-item>
      </div>
    </div>
  </div>
</div>