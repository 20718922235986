import { Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import * as Types from '../shared/types';
import { formatOptions } from '../shared/appUtil';
import * as Constants from '../shared/constant';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  constructor() {
    this._conversationOverview$ = new BehaviorSubject({} as Types.Overview);
    this._dateRange$ = new BehaviorSubject<Types.DateSelection>({
      startAPIDate: '',
      endAPIDate: '',
      dateSelected: false,
    });
    this._selectedColumns$ = new BehaviorSubject(['']);
    this._columnNames$ = new BehaviorSubject([] as Types.ColumnHeaders[]);
    this._totalCountSubject = new BehaviorSubject<number>(0);
    this._searchTermSubject = new BehaviorSubject<string>('');
    this._filterOnly$ = new BehaviorSubject<boolean>(false);
    this._dropDownToggleValues$ = new BehaviorSubject(Constants.InitialToggleValues as Types.DropDownToggleValues);
  }

  private _conversationOverview$: BehaviorSubject<Types.Overview>;
  private _dateRange$: BehaviorSubject<Types.DateSelection>;
  private _selectedColumns$: BehaviorSubject<string[]>;
  private _columnNames$: BehaviorSubject<Types.ColumnHeaders[]>;
  private _dropDownToggleValues$: BehaviorSubject<Types.DropDownToggleValues>;
  private _totalCountSubject = new BehaviorSubject<number>(0);
  private _searchTermSubject = new BehaviorSubject<string>('');
  private _filterOnly$ = new BehaviorSubject<boolean>(false);

  public apiData: any;
  public apiTotalRow: any;
  hiddenColumns: string[] = [];
  hiddenColumnsKey = 'hidden_columns';
  columnsFilterGroup: Types.FilterGroup = {
    name: 'ColumnsVisible',
    showName: 'Select Saved Filter',
    filters: {
      allSelected: false,
      tempAllSelected: false,
      options: formatOptions([]),
    },
  };

  updateFilterOptionsBasedOnSelection() {
    let selectedColumns: string[] = [];
    this.columnsFilterGroup.filters.options.forEach((option) => {
      option.isSelected = !this.hiddenColumns.includes(option.name);
      option.tempIsSelected = option.isSelected;
      if (!this.hiddenColumns.includes(option.name)) {
        selectedColumns.push(option.name);
      }
    });
    this._selectedColumns$.next(selectedColumns);
  }

  handlePopulateFilteredData = () => {
    let tempHiddenColumns: string[] = [];
    let tempSelectedColumns: string[] = [];

    this.columnsFilterGroup.filters.options.forEach((option) => {
      if (option.isSelected) {
        tempSelectedColumns.push(option.name);
      } else {
        tempHiddenColumns.push(option.name);
      }
    });

    // Store locally, batch update after processing
    this.hiddenColumns = tempHiddenColumns;
    localStorage.setItem(this.hiddenColumnsKey, JSON.stringify(tempHiddenColumns));

    if (!tempHiddenColumns.length || tempHiddenColumns.length === this.columnsFilterGroup.filters.options.length) {
      localStorage.removeItem(this.hiddenColumnsKey);
    }

    // Batch update of selected columns
    this._selectedColumns$.next(tempSelectedColumns);
  };

  handleHideAll = () => {};

  handleShowAll = () => {};

  setSelectedColumns() {
    let temp = localStorage.getItem(this.hiddenColumnsKey);
    if (temp) {
      this.hiddenColumns = JSON.parse(temp);
      this.updateFilterOptionsBasedOnSelection();
    } else {
      this.hiddenColumns = [];
      let selectedColumns = this._columnNames$.value.map((header) => header.name);
      this._selectedColumns$.next(selectedColumns);
    }
  }

  setColumnNames = (columnNames: Types.ColumnHeaders[]) => {
    this.columnsFilterGroup.filters.options = formatOptions(columnNames);
    this._columnNames$.next(columnNames);
  };

  getColumnNames = () => {
    return this._columnNames$;
  };

  get totalCount$(): Observable<number> {
    return this._totalCountSubject.asObservable();
  }

  setSearchTerm(searchText: string) {
    this._searchTermSubject.next(searchText);
  }

  getSearchTerm$(): Observable<string> {
    return this._searchTermSubject.asObservable();
  }

  setTotalCount(count: number) {
    this._totalCountSubject.next(count);
  }
  updateTotalCount(count: number) {
    this._totalCountSubject.next(count);
  }

  getColumnNamesValue = () => {
    return this._columnNames$.value;
  };

  getSelectedColumns = () => {
    return this._selectedColumns$;
  };

  getColumnsFilterGroup = () => {
    return this.columnsFilterGroup;
  };

  setDateRange(data: { startAPIDate: string; endAPIDate: string; dateSelected: boolean }) {
    this._dateRange$.next(data);
  }

  getDateRange() {
    return this._dateRange$.asObservable();
  }

  setDateSelected() {
    this._dateRange$.next({ ...this._dateRange$.value, dateSelected: false });
  }

  setConversationOverview(data: Types.Overview) {
    this._conversationOverview$.next(data);
  }

  getConversationOverview() {
    return this._conversationOverview$.asObservable();
  }

  setApiData(data: any): void {
    this.apiData = data;
  }

  getApiData(): any {
    return this.apiData;
  }

  setApiTotalRow(totalRow: any): void {
    this.apiTotalRow = totalRow;
  }

  getApiTotalRow(): any {
    return this.apiTotalRow;
  }

  getToggleValues() {
    return this._dropDownToggleValues$;
  }

  toggleHide(value: boolean) {
    this._dropDownToggleValues$.next({
      ...Constants.InitialToggleValues,
      hideFields: value,
    });
  }

  toggleFilter(value: boolean) {
    this._dropDownToggleValues$.next({
      ...Constants.InitialToggleValues,
      filter: value,
    });
    this.setFilterOnly(value);
  }

  toggleDate(value: boolean) {
    this._dropDownToggleValues$.next({
      ...Constants.InitialToggleValues,
      date: value,
    });
  }

  toggleMore(value: boolean) {
    this._dropDownToggleValues$.next({
      ...Constants.InitialToggleValues,
      moreOptions: value,
    });
  }

  toggleSearch(value: boolean) {
    this._dropDownToggleValues$.next({
      ...Constants.InitialToggleValues,
      search: value,
    });
  }

  public filterOnly(): BehaviorSubject<boolean> {
    return this._filterOnly$;
  }

  setFilterOnly(value: boolean): void {
    this._filterOnly$.next(value);
  }
}
